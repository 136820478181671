import React, {useEffect, useState,useRef} from 'react'
import { Dropdown , Popup, Menu} from 'semantic-ui-react'
import "semantic-ui-css/semantic.min.css";

function Menu_filtros(props) {
  const[activeItem,set_activeItem]=useState('home');

  const elimina_acentos = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  } 

  useEffect(() => {
  }, []); //Variable que con el cambio de esta, quiere que lance evento

  //Controla los clicks dados en el menú
  const handleItemClick = (name) => {
    set_activeItem(name);
    let filtros=props.datos_server.busca.filtro;
    switch(name){
      case 'quitar':
        filtros='';
        break;
      default:
        //Pone el filtro a mostrar
        const n_filtro=elimina_acentos(name);
        //Comprueba si ya existe
        if(!filtros.includes(n_filtro)){
          filtros+=','+n_filtro;
        }
        break
    }
    props.handleView('filtro',filtros);
  }

  //Asigna las filas para el menú filtros
  var filas=<></>;
  let filtros_actuales='';
  let muestra_filtros=<></>;
  if (props.datos_server.filtros !== undefined){
    //Recoge los filtros que se están utilizando actualmente
    let lst_filtros=[]; //props.datos_server.busca.filtro.split(',');
    //Obtiene el listado de filtros, eliminando aquellos items que no son filtros
    props.datos_server.busca.filtro.split(',').map((f) =>{
      if(f.length>1){
        lst_filtros.push(f);
      }
    });

    filtros_actuales=lst_filtros.filter(n => n).map((f)=>{
      return (<div>{f}</div>);
    })

    muestra_filtros=(
      <div style={{color: 'yellow'}}>
        {filtros_actuales.length>0 && <div>
          <div key='mu_filt_1'><b><u>Filtros activos</u></b></div>
          {filtros_actuales}
          </div>
        }
        <div/>
        {props.datos_server.busca.busca_alimento && <div>
          <div><b><u>Alimento buscado</u></b></div>
          <div>{props.datos_server.busca.busca_alimento}</div>
        </div>}
        <div/>
        {props.datos_server.busca.busca &&  <div>
          <div><b><u>Búsqueda inicial</u></b></div>
          <div>{props.datos_server.busca.busca}</div>
        </div>}
        <div/>
      </div>
    );
    //Recoge las filas del menú
    filas=crearMenu(props.datos_server.filtros);
  }

  //Crea el menú de filtros a partir de los filtros que llegan de la consulta
  function crearMenu(json) {
    const grupos = {};
    const grupos_list=[]
    // Agrupar elementos por grupo
    json.forEach((elemento) => {
      if (!grupos[elemento.grupo]) {
        grupos[elemento.grupo] = [];
        grupos_list.push(elemento.grupo);
      }
      grupos[elemento.grupo].push(elemento);
    });

        //Crear el menú de filtros en el dropdown del menu principal
        const opciones = grupos_list.map((grupo) => {
          return (
              <Dropdown.Item>
                <Dropdown
                  key={grupo}
                  text={grupo} 
                  style={{ fontSize: '15px',textAlign: 'right' }}
                >
                  <Dropdown.Menu>
                    {grupos[grupo].map((elemento,index) => {
                        return (
                          <Popup  trigger={<Dropdown.Item 
                              key={'pop_'+grupo+elemento.nombre+index}
                              text={elemento.nombre} 
                              active={activeItem === 'inicio'} 
                              onClick={() => handleItemClick(elemento.nombre)} 
                            />} 
                            content=<div dangerouslySetInnerHTML={{ __html: elemento.descripcion }} /> />
                        );
                      })};
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Item>
              );
          });
  
      return opciones;
    }
    
    return (
      <Menu secondary vertical>
        <Menu.Item style={{ fontSize: '15px',textAlign: 'left' }}>
          {muestra_filtros}
        </Menu.Item>
        <Menu.Item style={{ fontSize: '15px',textAlign: 'left' }}
          active={activeItem === 'inicio'} 
          onClick={() => handleItemClick('quitar')} 
        >
          Quitar filtros 
        </Menu.Item>
        {filas}
      </Menu>
    );

/*    //Crear el menú de filtros en el dropdown del menu principal
    const opciones = grupos_list.map((grupo) => {
        return (
            <Dropdown.Item>
              <Dropdown
                key={grupo}
                text={grupo} 
              >
                <Dropdown.Menu>
                  {grupos[grupo].map((elemento,index) => {
                      return (
                        <Popup  trigger={<Dropdown.Item 
                            key={'pop_'+grupo+elemento.nombre+index}
                            text={elemento.nombre} 
                            active={activeItem === 'inicio'} 
                            onClick={() => handleItemClick(elemento.nombre)} 
                          />} 
                          content=<div dangerouslySetInnerHTML={{ __html: elemento.descripcion }} /> />
                      );
                    })};
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
            );
        });

    return opciones;
  }
  
  return (
    <Dropdown key='mn_drop' item text='Filtros'>
        <Dropdown.Menu>
            <Popup  key='pop_filts' trigger={<Dropdown.Item 
                            key='pop_filt_act'
                            text='Filtros activos' 
                            />} 
                            content={muestra_filtros}
            />
            <Dropdown.Item 
                key='quitar'
                text='Quitar filtros' 
                active={activeItem === 'inicio'} 
                onClick={() => handleItemClick('quitar')} 
            />
            {filas}
        </Dropdown.Menu>
    </Dropdown>
  ); */
}

export default Menu_filtros;