import React, {useEffect, useState} from 'react'
import { Dropdown , Menu, Icon} from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import "semantic-ui-css/semantic.min.css";
import {url_base} from "../principal/funciones.js"

import Menu_principal from './menu_principal.jsx';

export default function MenuSup(props) {
  const navigate = useNavigate()
  const[options,set_options]=useState([]);
  const[busca_al,set_busca_al]=useState('')
  const[muestraMenu, set_muestraMenu] = useState(false);
  //Utilizada esta variable local poruqe desaparecia las introducciones de letras
  let pal_busca=''

  //Muestra o oculta el menu principal
  const manejamuestraMenu = () => {
    set_muestraMenu(!muestraMenu);
  };
  //Muestra las propiedades del alimento
  const handleInfoAlimento = () => {
    //Muestra la info nutricional del alimento
    navigate('/info_alimento', { state: { busca_al } });
  }

  useEffect(() => {
    //Detecta la pulsación de enter
    const keyDownHandler = e => {
      //Comprueba si se ha pulsado enter
      if (e.key === 'Enter'){
        //Envia la palabra almacenada en buscar, ya que al pulsar enter, e.target.value se borra
        //Realiza la búsqueda
        //Pone en la propiedad las palabras a buscar y cuando esta propiedad cambia,
        //en principal.jsx se ejecuta la función para realizar la busqueda.
        props.handleView('busca',pal_busca);   
        set_busca_al(pal_busca);
      }
    };
    //Asocia el evento para las entradas de teclado
    document.addEventListener('keydown', keyDownHandler);
    //Asocia el evento para las entradas de teclado, incluyendo para móvil
    const inputElement = document.querySelector('input');

    inputElement.addEventListener('input', (event) => {
      const value = event.target.value;
      pal_busca=value;
//      set_busca(value);
      //Realiza una sugerencia de palabras que empiezan por
      seriesSuggestions(value);
    });
    //Quita el evento de la función para evitar repetitibilidad
    return () => {
      document.removeEventListener('input', keyDownHandler);
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []); //Variable que con el cambio de esta, quiere que lance evento

  async function seriesSuggestions(string) {
    // Function gets a string and returns a list of series based on the string
    // This is for the search function
    //Realiza consulta para ver las palabras que empiezan por lo que se esta introduciendo
    let url = url_base();
    await fetch(url+`API/database.php`, { 
        method: 'POST',
        body: JSON.stringify({
          query_alimento: string.trim(),
        }),
        headers: {
          Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
        },
      })   
      .then(response => response.json())
      .then(res => {
          let list=res.results;
          //Publica el listado de recetas
          if (typeof list !='undefined'){
            //Crea la lista de opciones, que son las palabras encontradas
            let options_lst = list.map((x) => (
              {key: x, text: x, value: x}
            ));
            //Establece en el cuadro de búsqueda, las palabras encontradas
            set_options(options_lst);
          }
      });
  }

  const handleSelect = (e, data) => {
    const { value } = data.options.find((option) => option.text === data.value);
    pal_busca=value;
    set_busca_al(value);
    props.handleView('busca',value);  
  };

  return (
    <div>
      <Menu inverted >
        <Menu.Item key='mn_ini' position='left' onClick={manejamuestraMenu}>
          <Icon 
            inverted
            name='bars' 
            size='large' 
          />
        </Menu.Item>
        <Menu.Item key='mn_bus' position='right'>
          <Icon 
            inverted
            name='info' 
            size='large' 
            onClick={handleInfoAlimento}
          />
          <Dropdown
            position='right'
            icon='search' 
            placeholder='Alimento...' 
            options={options}
            search
            selection
            onChange={handleSelect} // Captura el evento de selección de una opción
            value={pal_busca}
          />
        </Menu.Item>
      </Menu>
      <Menu_principal visible={muestraMenu} handleView={props.handleView} datos_server={props.datos_server}></Menu_principal>  
    </div>
  );
}
