import React, {useEffect, useRef, useState} from 'react';
import { Segment, Pagination} from 'semantic-ui-react'
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom'
import {url_base} from "./funciones.js"

import MenuSup from '../menu/menu.jsx';
import ListadoCell from './ListadoCell.jsx';

import "./custom.css"

function Listados(){
  const location = useLocation();
  const cookies=localStorage.getItem('cookies')==='1'?true:false;
  const navigate = useNavigate()
  //Recoge el datos_server de recetas pasado por seleccion_iconos.jsx
  const datos=localStorage.getItem('datos');
  const[datos_server,set_datos_server]=useState(datos && datos!=='null'?JSON.parse(datos):null); 
  const pagina=localStorage.getItem('pagina');
  const[activePage, setActivePage] = useState(pagina && pagina.length!==0?pagina:1);
  const pos_id=useRef(localStorage.getItem('posicion'));
  const [pal_buscar,set_pal_buscar]=useState('');

  const handlePaginationChange = (event, { activePage }) => {
    setActivePage(activePage);
  };

  //Ejecuta antes del renderizado y es para realizar la búsqueda de las recetas
  useEffect(() => {
    //Comprueba si los datos viene almacenados en memoriapor venir de trabajo_recetas
    const datos_trabajo=JSON.parse(localStorage.getItem('data_trabajo'))
    //Si entra normal, borra los datos de trabajo
    //if(datos_server===null){
    //  localStorage.setItem('data_trabajo',null);
    //}
    //Realiza la búsqueda de los datos al introducirse por primera vez
    if(datos_server===null || datos_trabajo) do_search(datos_trabajo?datos_trabajo:location.state.data,activePage);
    //Comprueba si están almacenadas las cookies
    if (cookies){
      //Borra todos los valores al entrar
      localStorage.setItem('posicion',0);
      localStorage.setItem('pagina','');
      localStorage.setItem('datos',null);
    }
  },[]) 

  //Actualiza ante un cambio de página
  useEffect(()=>{
    if(pal_buscar.length>0){
      datos_server.busca.busca_alimento=pal_buscar;
      set_pal_buscar('');
    }
    if (datos_server!==null) do_search(datos_server.busca,activePage);
      // eslint-disable-next-line
  },[activePage,pal_buscar]) //Salta el evento por cambios en busca, filtro o salto de página

  //Realiza la búsqueda después del renderizado y es para posicionarse
  useEffect(() => {
    //Comprueba si están almacenadas las cookies
    if (cookies){
      //Al volver de cambiar la pantalla, se posiciona en la receta que se ha pulsado
      const elementoEnfocar = document.getElementById(pos_id.current);
      if (elementoEnfocar) {
        elementoEnfocar.scrollIntoView();  
      }else{
        if (datos_server){
          const enfoqueinicio=document.getElementById(datos_server.ids[0]);
          if (enfoqueinicio){
            enfoqueinicio.scrollIntoView();
          }
        }
      }
    }
  }) //Quitando los estados, ejecuta la función después del renderizado

  //Maneja la pulsación del menú de cada receta. Llamado desde lista_receta.jsx
  const handleItemClick =(id, item) => {
    let selected_id=id;
    if(item===0){
        //Muestra los ingredientes de esta receta
        navigate("/ListaIngredientes",{ state: { selected_id } });
        //Aqui id tiene el id y los ingredientes
        selected_id=id[0];
    }else if(item===1){
        //Lanza una consulta para ver los nutrientes de esta receta y los muestra en una pantalla superpuesta
        navigate("/TablaNutsReceta",{ state: { selected_id } });
    }else if(item===2){
        //Muestra la pantalla de problemas
        navigate("/problemas",{ state: { selected_id } });            
    }
    //Indica la posición desde la que se ha llamado, para que en la vuelta la muestre
    if(cookies){
        //Guarda el id que ha realizado la llamada
        localStorage.setItem('posicion',selected_id);
        //Guarda la página donde está el id
        localStorage.setItem('pagina',activePage);
        //Guarda la información de la página actualizada
        localStorage.setItem('datos',JSON.stringify(datos_server));
    } 
  }

  //Maneja las modificaciones en los filtros y las búsquedas
  const handleView = (tipo,valor) => {
    //Si cambia cualquier valor, lo pone en la página inicial
    setActivePage(1);
    switch(tipo){
      case 'busca':
        //Cambia la variable de búsqueda de palabra, activando la pal_buscar
        //no se porque, esto se ejecuta en menu.jsx y cuando llega aqui datos_server esta en null
        set_pal_buscar(valor);
        break;
      case 'filtro':
        //Cambia la variable de busqueda de filtros
        datos_server.busca.filtro=valor;
        if(valor.length===0){
          datos_server.busca.busca='';
        }
        //Si no hay cambio de página ejecuta la búsqueda desde aquí,
        //en otro caso se activa el useEffect de activePage y realiza la búsqueda
        if (activePage===1) do_search(datos_server.busca,activePage);
        break;
    }
  }
  /*
  { //      ?${cadena_vars}`,{
      method: 'POST',
      body: JSON.stringify({
        busca: mostrando.busca,
        filtro: mostrando.filtro,
        busca_alimento: mostrando.busca_alimento,
        pos: posicion,
      }),
      headers: {
        Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    */


  //Realiza la consulta de búsqueda para cambiar la lista
  async function do_search(mostrando,posicion){
    //Realiza la busqueda
    let url=url_base();   
    await fetch(url+`API/database.php`, { 
      method: 'POST',
      body: JSON.stringify({
        busca: mostrando.busca,
        filtro: mostrando.filtro,
        busca_alimento: mostrando.busca_alimento,
        pos: posicion,
      }),
      headers: {
        Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then(response => 
      response.json())
    .then(res => {
        //Publica los datos_server de recetas
        set_datos_server(res);
    });
  }
  
  //Muestra el segmento principal, que es el listado
  const segmento_medio = () =>{
    //Llama a lista_recetas.jsx para mostrar el datos_server de recetas
    return(
      <Segment style={{overflow: 'auto', maxHeight: 'calc(100vh - 150px)', padding: '0px' ,marginTop: '65px' }}>
        <ListadoCell datos_server={datos_server} handleItemClick={handleItemClick} pos_id={pos_id.current}></ListadoCell>
      </Segment>);
  }
  //Muestra el pie de la página
  const segmento_final = () =>{
    //Muestra las barras de siguentes páginas
    return(
      <Segment className="pagination-container">
        <Pagination
          activePage={activePage}
          onPageChange={handlePaginationChange}
          totalPages={datos_server===null?0:datos_server.max}
          siblingRange= {1} //{Math.floor((maxVisiblePages - 1) / 2)} // Ajusta el rango de páginas visibles
          boundaryRange={0} // No muestra los puntos suspensivos de inicio y fin
          ellipsisItem={null} // No muestra los puntos suspensivos entre páginas
        />
    </Segment>
    );
  }

  //Muestra el contenido de la página
  return(
    <div>
      <div className="sticky-segment">  
        <MenuSup datos_server={datos_server} handleView={handleView}></MenuSup>
      </div>
      <Segment.Group style={{width: 'unset',  width: '100%', zIndex: 1}}>
        {segmento_medio()}
        {segmento_final()}
      </Segment.Group> 
    </div>);
}

export default Listados;
