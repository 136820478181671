import { Table } from 'semantic-ui-react';
import React, { useEffect, useState, useRef } from 'react';
import {url_base} from "./funciones.js"
import './custom.css';

export default function Alimentos_nutriente(props) {
    const [lst_alimentos,set_lst_alimentos]=useState([]);

    useEffect(() => {
        view_alimentos_nutrientes(props.nutriente);
    }, []); //Si esta vacio se ejecuta solo en el primer renderizado

    //Realiza la consulta para guarda la información nutriciona
    async function view_alimentos_nutrientes(data){
        //Compone la cadena de búsqueda
        let url = url_base();
        await fetch(url + `API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
                alimentos_nutriente: data,
            }),
            headers: {
                Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
            })           
        .then(response => response.json())
        .then(res => {
            if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                set_lst_alimentos(res)
            }else{
                //ERROR, no lo ha podido procesar
            }  

        });
    }  

    return (
        <Table>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Alimento</Table.HeaderCell>
                <Table.HeaderCell>Valor</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
                {lst_alimentos.map((alimento, index) => {
                    {/*Busca en los alimentos, los que están contenidos en la receta, para marcarlos */}
                    const esMarcado = props.en_trabajo?props.receta.includes(alimento.alimento_st):false;
                    return (
                    <Table.Row key={index} negative={esMarcado}>
                        <Table.Cell>{alimento.alimento}</Table.Cell>
                        <Table.Cell>{alimento.valor}</Table.Cell>
                    </Table.Row>
                    );
                })}
            </Table.Body>
        </Table>
    );
}