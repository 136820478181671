import React, { useState, useEffect,useRef } from "react";
import { Table,Icon } from "semantic-ui-react";

export default function FileExplorerCell({datos,handleFileClick}){
    return(
        <>
            {datos && 
                <Table.Row
                    onClick={() => handleFileClick(datos.nombre, datos.tipo)}
                >
                    <Table.Cell style={{ width: '5%' }}>
                        {datos.tipo === 'carpeta' ? <Icon name="folder" /> : <Icon name="file" />}
                    </Table.Cell>
                    <Table.Cell style={{ width: '10%' }}>{datos.nombre}</Table.Cell>
                    <Table.Cell style={{ width: '5%' }}>{datos.peso} gr</Table.Cell>
                    <Table.Cell style={{ width: '5%' }}>{datos.nutrientes['Energy']?parseFloat(datos.nutrientes['Energy']).toFixed(0):0} Kcal</Table.Cell>
                    <Table.Cell style={{ width: '30%' }}>{JSON.stringify(datos.ingredientes)}</Table.Cell>
                    <Table.Cell style={{ width: '50%' }}>{JSON.stringify(datos.nutrientes)}</Table.Cell>
                </Table.Row>
            }
        </>
    )

}