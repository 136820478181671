
import React, { useEffect, useState, useRef } from 'react';
import { Segment,Label,Table, Button, Icon,Message, Input } from "semantic-ui-react";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {url_base} from "./funciones.js"
import Caracteristicas from './caracteristicas.jsx';
import './custom.css';

export default function Receta() {
    const cookies=localStorage.getItem('cookies')==='1'?true:false;
    const location = useLocation();
    const navigate = useNavigate()
    const [ingredientes, set_ingredientes] = useState([]);
    const [mode_edit,set_mode_edit]=useState(false);
    const [inputTitle, setInputTitle] = useState('');
    const [inputText, setInputText] = useState('');
    const [inputElaboracion, setInputElaboracion] = useState('');
    const [nutrientes, set_nutrientes] = useState(null);
    const [load_nuts,set_load_nuts]=useState(false);

    useEffect(() => {
        // Recuperar la cadena JSON desde localStorage
        const dataString = localStorage.getItem('dataToSend');
        // Convertir la cadena JSON a un objeto JavaScript
        const dataToSend = JSON.parse(dataString);
        // Verificar si se recuperó correctamente
        if (dataToSend) {
            setInputTitle(dataToSend.receta);
            set_ingredientes(dataToSend.ingredientes);
            setInputElaboracion(dataToSend.elaboracion);
            // Haz algo con los datos recuperados
            localStorage.removeItem('dataToSend');
        } else {
            // No se encontraron datos en localStorage o hubo un error al recuperarlos
        }
        // Recuperar la cadena JSON desde localStorage
        const datanuts = localStorage.getItem('data_nuts');
        // Convertir la cadena JSON a un objeto JavaScript
        const data_n_proc = JSON.parse(datanuts);
        // Verificar si se recuperó correctamente
        if (data_n_proc) {
            set_nutrientes(data_n_proc);
            // Haz algo con los datos recuperados
            localStorage.removeItem('data_nuts');
        } else {
            // No se encontraron datos en localStorage o hubo un error al recuperarlos
        }
    }, []); //Si esta vacio se ejecuta solo en el primer renderizado

    //Realiza la consulta para guarda la información nutriciona
    async function view_nutrientes(data){
        //Compone la cadena de búsqueda
        set_load_nuts(true); 
        let url = url_base();
        await fetch(url + `API/consulta`, { 
            method: 'POST',
            body: JSON.stringify({
              accion: 'receta',
              parametro: data,
            }),
            headers: {
              Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
          })           
        .then(response => response.json())
        .then(res => {
            if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                //Publica el listado de recetas
                if ('info' in res){
                    set_nutrientes(res);
                    muestra_Nutrientes(res);
                }else{
                    //ERROR, no lo ha podido procesar
                }            
            }else{
                //ERROR, no lo ha podido procesar
            }  

            set_load_nuts(false);
        });
    }  

    const handleNutrientes = () => {
        if(nutrientes){
            muestra_Nutrientes(nutrientes);
        }else{
            const dataToSend = { receta: inputTitle, ingredientes: ingredientes, elaboracion: inputElaboracion };
            view_nutrientes(dataToSend);
        }
    }

    const muestra_Nutrientes = (nuts) => {
        const dataToSend = { receta: inputTitle, ingredientes: ingredientes, elaboracion: inputElaboracion };
        // Convertir el objeto a una cadena JSON
        const dataString = JSON.stringify(dataToSend);
        // Guardar la cadena JSON en localStorage
        localStorage.setItem('dataToSend', dataString);
        //guarda los nutrientes 
        localStorage.setItem('data_nuts', JSON.stringify(nuts));
        navigate("/TablaNutsReceta",{ state:  nuts  });
    };

    const handleClose = () => {
        if(mode_edit){
            set_mode_edit(false);
        }else{
            localStorage.removeItem('dataToSend');
            localStorage.removeItem('data_nuts');
            navigate(-1);
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const newRows = [...ingredientes];
        newRows[index] = value;
        set_ingredientes(newRows);
    };

    const handleAddRow = () => {
        set_ingredientes([...ingredientes,  "" ]);
        set_nutrientes(null);
    };

    const handleDeleteRow = (index) => {
        const newRows = [...ingredientes];
        newRows.splice(index, 1);
        set_ingredientes(newRows);
        set_nutrientes(null);
    };

    const handleSave = () => {
        /*
        const dataToSend = { receta: inputTitle, ingredientes: ingredientes, elaboracion: inputElaboracion };
        console.log(JSON.stringify(dataToSend));
        do_search(dataToSend);
        */
    };

    const handleEdit = () => {
        set_mode_edit(!mode_edit);
    };

    const handleInputTitleChange = (e) => {
        setInputTitle(e.target.value);
    };

    const handleInputTextChange = (e) => {
        setInputText(e.target.value);
    };

    const handleInputElaboracionChange = (e) => {
        setInputElaboracion(e.target.value);
    };

    const handleConvertToList = () => {
        // Utilizar expresiones regulares para dividir el texto en elementos separados
        const items = inputText.split(/;|\r?\n/);

        // Eliminar espacios vacíos al inicio o final de cada item
        const trimmedItems = items.map((item) => item.trim());

        // Filtrar y eliminar elementos vacíos
        const nonEmptyItems = trimmedItems.filter((item) => item !== '');

        // Actualizar la lista con los elementos convertidos
        set_ingredientes(nonEmptyItems);
        set_nutrientes(null);
    };

    return (

        <Table celled unstackable={true} size={'small'}>
            <Table.Header>
            <Table.Row  id={'id0'}>
                <Table.HeaderCell>
                    <div>
                        Receta de 
                        <Input
                            style={{width:'75%', paddingLeft: 5}}
                            placeholder="Nombre de la receta"
                            value={inputTitle}
                            onChange={handleInputTitleChange}
                        >
                        </Input>
                    </div>
                    {nutrientes &&
                        <div>
                            <div>
                                <Caracteristicas datos={nutrientes.caracteristicas}></Caracteristicas>
                            </div>
                            <div>
                                {nutrientes.cal} kcal ración
                            </div>
                        </div>
                    }
                </Table.HeaderCell>
                {mode_edit &&
                    <Table.HeaderCell>
                    <Button icon onClick={handleAddRow}>
                        <Icon name="plus" />
                    </Button>
                    </Table.HeaderCell>
                }
            </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell colSpan="2" style={{padding:0 }}>
                        <Segment>
                            <Label attached='top'>Receta</Label>
                            <textarea
                                placeholder="Ingresa texto separado por ; o retorno de carro"
                                value={inputText}
                                onChange={handleInputTextChange}
                                style={{ width: '100%', height: '100px', resize: 'vertical', marginBottom: '10px' }}
                            />
                            <button onClick={handleConvertToList}>Convertir a lista</button>
                        </Segment>
                    </Table.Cell>
                </Table.Row>
            {ingredientes.map((row, index) => (
                <Table.Row key={index}>
                <Table.Cell className="ui celled table cell-space">
                    <input
                    className="input-full-cell"
                    type="text"
                    name="ingrediente"
                    value={row}
                    onChange={(e) => handleInputChange(e, index)}
                    />
                </Table.Cell>
                {mode_edit &&
                <Table.Cell>
                    <Button icon  size='mini' onClick={() => handleDeleteRow(index)}>
                        <Icon name="trash alternate" />
                    </Button>
                </Table.Cell>
                }
                </Table.Row>
            ))}
            <Table.Row>
                <Table.Cell colSpan="2" style={{padding:0 }}>
                    <Segment>
                        <Label attached='top'>Elaboración/Apuntes</Label>
                        <textarea
                            placeholder="Ingresa Elaboración/Apuntes"
                            value={inputElaboracion}
                            onChange={handleInputElaboracionChange}
                            style={{ width: '100%', height: '100px', resize: 'vertical', marginBottom: '10px' }}
                        />
                    </Segment>
                </Table.Cell>
                </Table.Row>
            </Table.Body>

            <Table.Footer>
            {!cookies && 
                <Table.Row>
                    <Table.HeaderCell colSpan="2">
                        <Message info>
                            <Message.Header>Cookies</Message.Header>
                            <p>Al no tener las cookies activadas, al volver atrás vuelve al inicio de la consulta</p>
                        </Message>    
                    </Table.HeaderCell>
                </Table.Row>
            }
            <Table.Row>
                <Table.HeaderCell colSpan="2">
                    {!mode_edit && <Button primary onClick={handleEdit}>
                        Editar
                    </Button>
                    }
                    {mode_edit && <Button primary onClick={handleSave}>
                        Save
                    </Button>
                    }
                    <Button secondary onClick={handleClose}>
                        {mode_edit?'Cancelar':'Salir'}
                    </Button>
                    {!mode_edit && 
                        <Button floated='right' 
                            onClick={handleNutrientes}
                            loading={load_nuts}>
                        Ver Nutrientes
                    </Button>}
                </Table.HeaderCell>
            </Table.Row>
            </Table.Footer>
        </Table>
        );  
    }