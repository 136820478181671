
import React, { useEffect, useState, useRef } from 'react';
import { Segment,Label,Table, Button, Checkbox, Input,Grid, Modal,Message,Tab,Dropdown } from "semantic-ui-react";
import { useNavigate } from 'react-router-dom'
import {url_base,explora_ficheros} from "./funciones.js"

import Caracteristicas from './caracteristicas.jsx';
import TablaNutsReceta from './TablaNutsReceta.jsx';
import TablaNutsAlimento from './TablaNutsAlimento.jsx';
import Beneficios from './beneficios.jsx';
import VistaNutsAlimentos from './VistaNutsAlimento.jsx';
import './custom.css';

import BotonLoadRec from './BotonLoadRec.jsx';

export default function TrabajarRecetas() {
    const cookies=localStorage.getItem('cookies')==='1'?true:false;
    const navigate = useNavigate()
    const [modal_Abierto, set_modal_Abierto] = useState(false);
    const [datos_modal_Alimento, set_datos_modal_Alimento] = useState(null);
    const [datos_modal_Peso, set_datos_modal_Peso] = useState(null);
    const [selected_modal_Alimento, set_selected_modal_Alimento] = useState([]);
    const [show_mensaje, set_show_mensaje] = useState(false);
    const [mensaje, set_mensaje] = useState(null);
    // Obtener el valor almacenado en localStorage
    const storedValue = localStorage.getItem('selectedRacion');
    const [selectedValueRacion, setSelectedValueRacion] = useState(storedValue || '');
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [contenido,set_contenido]=useState({
        receta: "",
        ingredientes: "",
        elaboracion: "",
        nutrientes: "",
        lst_ingredientes: "",
    });

    useEffect(() => {
        if (mensaje) {
            const timer = setTimeout(() => {
                set_show_mensaje(false);
            }, 10000); // 10000 milisegundos = 10 segundos

            return () => {
                clearTimeout(timer);
            };
        }
    }, [mensaje]);

    //Realiza la consulta para guarda la información nutriciona
    async function view_nutrientes(data){
        //Compone la cadena de búsqueda
        let url = url_base();
        await fetch(url + `API/consulta`, { 
            method: 'POST',
            body: JSON.stringify({
                accion: 'TrabajarRecetas',
                parametro: data,
            }),
            headers: {
                Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
            })           
        .then(response => response.json())
        .then(res => {
            if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                //Publica el listado de recetas
                if ('info' in res){
                    set_contenido((prevContenido) => ({
                        ...prevContenido, // Mantener el resto de las claves intactas
                        nutrientes: res, // Actualizar solo la clave 
                    }));
                    set_selected_modal_Alimento(res.receta);
                }else{
                    //ERROR, no lo ha podido procesar
                }            
            }else{
                //ERROR, no lo ha podido procesar
            }  
        });
    }

    const handleCloseMessage = () => {
        set_show_mensaje(false);
    };

    const handleListaRecetas = () =>{
        if(contenido.nutrientes){
            //Carga los datos para la visualización de recetas
            const filtro=',,,,,';
            const busca='';
            const busca_alimento=selected_modal_Alimento;
            const data_lst = {
                busca, // objeto busca
                filtro, // objeto filtro
                busca_alimento,
            };
            // Abre el modal y envia los datos a '/Listado' 
            localStorage.setItem('data_trabajo',JSON.stringify(data_lst));
        }
    }
  
    const handleAbrirModal = (alimento,peso) => {
        set_datos_modal_Alimento(alimento);
        set_datos_modal_Peso(peso);
        set_modal_Abierto(true);
    };

    const handleCheckboxChange = (alimento, isChecked) => {
        if (isChecked) {
          set_selected_modal_Alimento([...selected_modal_Alimento, alimento]);
        } else {
          set_selected_modal_Alimento(selected_modal_Alimento.filter((id) => id !== alimento));
        }
    };

    let filas_nuts=<Table.Row></Table.Row>
    if (contenido.nutrientes && Object.keys(contenido.nutrientes.receta).length>0){
        filas_nuts= Object.keys(contenido.nutrientes.receta).map((key) => (
            <Table.Row key={contenido.nutrientes.receta[key]}>
                <Table.Cell
                    onClick={() => handleAbrirModal(contenido.nutrientes.receta[key],contenido.nutrientes.medidas[key]*100)}
                >
                    {contenido.nutrientes.receta[key]}
                </Table.Cell>
                <Table.Cell>
                    {contenido.nutrientes.medidas[key]}
                </Table.Cell>
                <Table.Cell>
                    <Checkbox
                        defaultChecked
                        onChange={(e, { checked }) => handleCheckboxChange(contenido.nutrientes.receta[key], checked)}
                    />
                </Table.Cell>
            </Table.Row>
          ));
    }
    let filas_desglose=<Table.Row></Table.Row>
    if (contenido.nutrientes && Object.keys(contenido.nutrientes.des_al_rec).length>0){
        filas_desglose= Object.keys(contenido.nutrientes.des_al_rec).map((key) => (
            <Table.Row key={contenido.nutrientes.des_al_rec[key]+'_'+key}>
                <Table.Cell>
                    {contenido.nutrientes.des_al_rec[key]}
                </Table.Cell>
                <Table.Cell>
                    {contenido.nutrientes.des_al_ctd[key]}
                </Table.Cell>
                <Table.Cell>
                    {contenido.nutrientes.des_al_med[key]}
                </Table.Cell>
            </Table.Row>
          ));
    }

    const PestanaInfoNut= () => {

        const handleDropdownChangeRacion = (event, data) => {
          setSelectedValueRacion(data.value);
          localStorage.setItem('selectedRacion', data.value);
        };

        const options_pesos = [
            { key: 'toda', text: 'Peso receta', value: -1 },
            { key: '100gr', text: '100 gr', value: 100 },
            { key: '200gr', text: '200 gr', value: 200 },
            { key: '300gr', text: '300 gr', value: 300 },
            { key: '400gr', text: '400 gr', value: 400 },
            { key: '500gr', text: '500 gr', value: 500 },
          ];

        const filteredOptionsPeso = options_pesos.filter(option => {
            if (option.value === -1) {
              return true; // Siempre mostrar la opción "Peso receta"
            }
            return contenido.nutrientes?option.value <= contenido.nutrientes.peso:true; // Mostrar opciones hasta el valor de "peso"
        });

        return (
            <>
                <Dropdown
                    placeholder="Selecciona ración"
                    selection
                    options={filteredOptionsPeso}
                    value={selectedValueRacion}
                    onChange={handleDropdownChangeRacion}
                />
                <TablaNutsReceta datos={contenido} setDatos={set_contenido} pesoRacion={selectedValueRacion}/>
            </>
        );
    };

    const buscaAlimento=useRef('');
    const panes = [
        { menuItem: 'Beneficios', render: () => <Tab.Pane>
                                                    <Beneficios />
                                                </Tab.Pane> },
        { menuItem: 'Alimentos', render: () =>  <Tab.Pane>
                                                    <VistaNutsAlimentos buscaAlimento={buscaAlimento} />
                                                </Tab.Pane> },
        { menuItem: 'Nutrientes', render: () => <Tab.Pane>
                                                    <PestanaInfoNut />
                                                </Tab.Pane> },
    ];

    const CarpetaReceta = ({contenido,setTipoReceta,tipoReceta}) => {
        const [showInput, setShowInput] = useState(false);
        const [currentPath, setCurrentPath] = useState("/");
        const [files, setFiles] = useState([]);
        const inputRef = useRef(null);
      
        useEffect(() => {
          explora_ficheros('', setFiles, setCurrentPath);
        }, []); // Variable que con el cambio de esta, quiere que lance evento
      
        useEffect(() => {
            if(contenido.carpeta){
                setTipoReceta(contenido.carpeta);
            }
          }, [contenido]); // Variable que con el cambio de esta, quiere que lance evento

        const options = [
          { key: 'NUEVA', text: 'NUEVA', value: 'NUEVA' },
          { key: '/', text: '/', value: '' },
          ...files.filter(item => item.tipo === 'carpeta').map(item => ({
            key: item.nombre,
            text: item.nombre,
            value: item.nombre
          }))
        ];
      
        const handleDropdownChange = (e, { value }) => {
          if (value === 'NUEVA') {
            setShowInput(true);
            setTimeout(() => inputRef.current.focus(), 0);
          } else {
            setTipoReceta(value);
            setShowInput(false);
            contenido['carpeta'] = value;
        }
        };
      
        return (
          <div>
            {showInput ? (
              <Input
                placeholder="Introduce un nombre..."
                value={tipoReceta}
                onChange={(e) => setTipoReceta(e.target.value)}
                onBlur={() => {
                  if (tipoReceta) {
                    setFiles([...files, { nombre: tipoReceta, tipo: 'carpeta' }]);
                    contenido['carpeta'] = tipoReceta;
                    setShowInput(false);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && tipoReceta) {
                    setFiles([...files, { nombre: tipoReceta, tipo: 'carpeta' }]);
                    contenido['carpeta'] = tipoReceta;
                    setShowInput(false);
                  }
                }}
                ref={inputRef}
              />
            ) : (
              <Dropdown
                selection
                options={options}
                value={tipoReceta}
                onChange={handleDropdownChange}
                onFocus={() => setShowInput(false)}
              />
            )}
          </div>
        );
    };

    const Receta = ({contenidoLoc, set_contenidoLoc}) =>{
        const [receta,setReceta]=useState(!contenidoLoc?'':contenidoLoc.receta);
        const [ingrediente,setIngrediente]=useState(!contenidoLoc?'':contenidoLoc.lst_ingredientes);
        const [elaboracion,setElaboracion]=useState(!contenidoLoc?'':contenidoLoc.elaboracion);
        const [loading_nuts,set_loading_nuts]=useState(false);
        const [tipoReceta, setTipoReceta]=useState('');
        const contenido=contenidoLoc;
            
        //Realiza la consulta para guarda la información nutriciona
        async function guarda_fichero(data){
            //Comprueba que tenga nombre de fichero
            if (data.receta && data.receta.trim().length > 0) {
                let url = url_base();
                await fetch(url + `API/database.php`, { 
                    method: 'POST',
                    body: JSON.stringify({
                        recetas_trabajo_sf : data,
                    }),
                    headers: {
                        Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
                    },
                    })           
                .then(response => response.json())
                .then(res => {
                    if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                        //Publica el listado de recetas
                        if ('status' in res){
                            //ERROR, no lo ha podido procesar
                            set_show_mensaje(true)
                            set_mensaje(res.txt)                            
                        }else{
                            //ERROR, no lo ha podido procesar
                            set_show_mensaje(true)
                            set_mensaje('Error al recibir la respuesta del servidor.')
                        }            
                    }else{
                        //ERROR, no lo ha podido procesar
                        set_show_mensaje(true)
                        set_mensaje('Error al recibir la respuesta del servidor.')
                    }  
                });
            }else{
                set_show_mensaje(true)
                set_mensaje('Debe tener un nombre la receta.')
            }
        }  

        //Realiza la consulta para guarda la información nutriciona
        async function borra_fichero(data){
            //Comprueba que tenga nombre de fichero
            if (data.receta && data.receta.trim().length > 0) {
                let url = url_base();
                await fetch(url + `API/database.php`, { 
                    method: 'POST',
                    body: JSON.stringify({
                        recetas_trabajo_df : data,
                    }),
                    headers: {
                        Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
                    },
                    })           
                .then(response => response.json())
                .then(res => {
                    if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                        //Publica el listado de recetas
                        if ('status' in res){
                            //ERROR, no lo ha podido procesar
                            set_show_mensaje(true)
                            set_mensaje(res.txt)                            
                        }else{
                            //ERROR, no lo ha podido procesar
                            set_show_mensaje(true)
                            set_mensaje('Error al recibir la respuesta del servidor.')
                        }            
                    }else{
                        //ERROR, no lo ha podido procesar
                        set_show_mensaje(true)
                        set_mensaje('Error al recibir la respuesta del servidor.')
                    }  
                });
            }else{
                set_show_mensaje(true)
                set_mensaje('Debe tener un nombre la receta.')
            }
        }  

        const handleNutrientes = () => {
            // Utilizar expresiones regulares para dividir el texto en elementos separados
            const items = ingrediente.split(/;|\r?\n/);

            // Eliminar espacios vacíos al inicio o final de cada item
            const trimmedItems = items.map((item) => item.trim());

            // Filtrar y eliminar elementos vacíos
            const lst_ingr = trimmedItems.filter((item) => item !== '');

            // Actualizar la lista con los elementos convertidos
            set_contenidoLoc((prevContenido) => ({
                ...prevContenido, // Mantener el resto de las claves intactas
                ingredientes: lst_ingr, // Actualizar solo la clave 
                nutrientes: null, // Actualizar solo la clave 
                receta: receta,
                lst_ingredientes: ingrediente,
                elaboracion: elaboracion
            }));

            localStorage.setItem('data_trabajo',null);

            //Recoge los datos de los nutrientes
            const dataToSend = { receta: receta, ingredientes: lst_ingr, elaboracion: elaboracion };
            set_loading_nuts(true);
            view_nutrientes(dataToSend);
            set_loading_nuts(false);
        }

        const handleClose = () => {
            localStorage.setItem('data_trabajo',null);
            navigate(-1);
        };

        const handleSave = () => {
            // Crear una copia actualizada de 'contenido'
            let contenido_save = {
                ...contenido, // Copiar todas las propiedades del objeto original
                receta: receta, // Actualizar la propiedad 'receta' con un nuevo valor
                elaboracion: elaboracion // Actualizar la propiedad 'elaboracion' con un nuevo valor
            };
            //Actualiza el origen de los contenidos
            set_contenidoLoc(contenido_save);
            //guarda el fichero
            guarda_fichero(contenido_save);
        };

        const handleDel = () => {
            //guarda el fichero
            borra_fichero(contenido);
        };

        const handleInputTitleChange = (e) => {
            setReceta(e.target.value);
        };
    
        const handleInputTextChange = (e) => {
            setIngrediente(e.target.value);
        };
    
        const handleInputElaboracionChange = (e) => {
            setElaboracion(e.target.value);
        };

        const handleLoadReceta = (contenido) => {
            set_contenidoLoc(contenido)
            set_selected_modal_Alimento(contenido.nutrientes.des_al_rec);
        }

        return(
            <Table celled unstackable={true} size={'small'}>
                    <Table.Header>
                    <Table.Row  id={'id0'}>
                        <Table.HeaderCell>
                            <div>
                                Receta de 
                                <Input
                                    style={{width:'75%', paddingLeft: 5}}
                                    placeholder="Nombre de la receta"
                                    value={receta}
                                    onChange={handleInputTitleChange}
                                />
                            </div>
                            <div style={{display:'flex'}}>
                                Tipo Receta
                                <CarpetaReceta contenido={contenido} setTipoReceta={setTipoReceta} tipoReceta={tipoReceta}/>
                            </div>
                            {contenido.nutrientes &&
                                <div>
                                    <div>
                                        <Caracteristicas datos={contenido.nutrientes.caracteristicas}></Caracteristicas>
                                    </div>
                                    <div>
                                        <span>
                                            {contenido.nutrientes.cal} kcal ración
                                        </span>
                                        <span style={{float:'right'}}>
                                            Peso de la receta {contenido.nutrientes.peso} gr.
                                        </span>
                                    </div>
                                </div>
                            }
                        </Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{padding:0 }}>
                                <Segment>
                                    <Label attached='top' >Receta</Label>
                                    <textarea
                                        placeholder="Ingresa texto separado por ; o retorno de carro"
                                        value={ingrediente}
                                        onChange={handleInputTextChange}
                                        style={{ width: '100%', height: '100px', resize: 'vertical', marginBottom: '10px' }}
                                    />
                                </Segment>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{padding:0 }}>
                                <Segment>
                                    <Label attached='top'>Elaboración/Apuntes</Label>
                                    <textarea
                                        placeholder="Ingresa Elaboración/Apuntes"
                                        value={elaboracion}
                                        onChange={handleInputElaboracionChange}
                                        style={{ width: '100%', height: '100px', resize: 'vertical', marginBottom: '10px' }}
                                    />
                                </Segment>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button primary onClick={handleSave}>
                                Save
                            </Button>
                            <Button secondary onClick={handleClose}>
                                Salir
                            </Button>
                            <Button negative onClick={handleDel}>
                                Elimina
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <BotonLoadRec contenido={handleLoadReceta} ruta={tipoReceta.length?'/'+tipoReceta:''} />
                            <Button floated='right' 
                                onClick={handleNutrientes}
                                loading={loading_nuts}>
                                Procesar receta
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                    </Table.Footer>
                </Table>
        );
    }

    return (
        <div>
        {show_mensaje && (
            <Message
                onDismiss={handleCloseMessage}
                header="Atención"
                content={mensaje}
            />
        )}
        <Grid columns={3} divided>
        <Grid.Row style={{paddingLeft:10}}>
            <Grid.Column style={{padding:0, width: '25%'}}>
            {/* Tabla 1*/}
                <Receta contenidoLoc={contenido} set_contenidoLoc={set_contenido}/>
            </Grid.Column>

            <Grid.Column style={{padding:0, width: '15%'}}>
            {/* Tabla 2 */}
                <Table>
                    <Table.Header>                    
                        <Table.Row>
                            <Table.HeaderCell>Alimentos</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>                    
                        <Table.Row>
                            {filas_nuts}
                        </Table.Row>
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Button as="a"  href = '/Listado' target = "_blank" onClick={handleListaRecetas}> 
                                    Buscar Recetas
                                </Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Table>
                    <Table.Header>                    
                        <Table.Row>
                            <Table.HeaderCell>Receta desglosada</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>                    
                        <Table.Row>
                            {filas_desglose}
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Grid.Column>

            <Grid.Column style={{padding:0, width: '60%'}}>
                {/* Tabla 3 */}
                <Tab 
                    panes={panes}
                />
            </Grid.Column>
        </Grid.Row>
            {/* Modal */}
            <Modal open={modal_Abierto} onClose={() => set_modal_Abierto(false)}>
                <Modal.Header>Información de {datos_modal_Alimento} por {(datos_modal_Peso?datos_modal_Peso:0).toFixed(0)} gr. Son {contenido.nutrientes?(datos_modal_Peso/(contenido.nutrientes.peso/300)).toFixed(0):null} gr. por ración </Modal.Header>
                <Modal.Content>
                    {/* Renderiza el componente TablaNutsAlimento dentro del modal */}
                    <TablaNutsAlimento esModal busca_al={datos_modal_Alimento} peso={datos_modal_Peso} raciones={contenido.nutrientes?(contenido.nutrientes.peso/300):null} />
                </Modal.Content>
            </Modal>
        </Grid>
        </div>
        );  
    }