
import React from 'react';
import { Image, Popup } from 'semantic-ui-react'

import './custom.css';

import img_organos from '../imagenes/beneficios/organos.png'
import img_sentidos from '../imagenes/beneficios/sentidos.png'
import img_desarrollo from '../imagenes/beneficios/desarrollo.png'
import img_psicologico from '../imagenes/beneficios/psicologico.png'
import img_neurologico from '../imagenes/beneficios/neurologico.png'
import img_depura_refuerza from '../imagenes/beneficios/depura_refuerza.png'
import img_movilidad from '../imagenes/beneficios/movilidad.png'
import img_digestivo from '../imagenes/beneficios/digestivo.png'
import img_circulacion from '../imagenes/beneficios/circulacion.png'
import img_piel from '../imagenes/beneficios/piel.png'


export default function Caracteristicas(props) {

    let grupo_propiedades={'órganos':['riñón', 'próstata', 'vesícula biliar', 'tiroide', 'hígado'],'sentidos':['olfato', 'visión'],'desarrollo':['tejidos','sexualidad', 'genética', 'crecimiento', 'reproductivo'],
    'psicológico':[ 'estado de ánimo',  'cognitivo'],'neurológico':[ 'migraña', 'sistema nervioso'],'depura y refuerza':['detox', 'antioxidante', 'inmunitario','antiinflamatorio'],'movilidad':[ 'articulaciones',  'músculos', 'huesos',  'energía'],
    'digestivo':['digestivo'],'circulación':['diabetes','sistema circulatorio'],'piel':['piel']}

    let gp_icono={'órganos':img_organos,'sentidos':img_sentidos,'desarrollo':img_desarrollo,
    'psicológico':img_psicologico,'neurológico':img_neurologico,'depura y refuerza':img_depura_refuerza,'movilidad':img_movilidad,
    'digestivo':img_digestivo,'circulación':img_circulacion,'piel':img_piel}

    //Obtiene los beneficios de esta receta
    var propiedades={};
    for (const x in props.datos) {
        //Busca el grupo al que pertenece
        for (const p in grupo_propiedades) {
            if(props.datos[x][0]>200){
                if(grupo_propiedades[p].includes(x)){ 
                    //Almacena las propiedades
                    if (typeof propiedades[p] == 'undefined'){
                        propiedades[p]=[]
                    }
                    //Comprueba que no exista ya
                    if (!propiedades[p].includes(x)){
                        propiedades[p].push(x)
                    }
                }
            }
        }
    }
    //Compone los iconos de propiedades
    let contenido=Object.keys(propiedades).map(key =>{
        return (
            <Popup  key={propiedades[key].toString()} trigger={< Image avatar src = {gp_icono[key]}size = { 'mini' } circular/>} 
            content=<div>{propiedades[key].toString()}</div>  />
        );
    });

    return(
        <div  align="right" style={{width: props.width , display:'inline-block'}}>
            {contenido}
        </div>
    );
    

}