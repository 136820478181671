import React, { useEffect, useState } from 'react';
import { Message, Button,Segment,Label, } from 'semantic-ui-react';

const Banner_cookies = ({ cookiesAccepted }) => {
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
      //Comprueba si están almacenadas las cookies
      if (localStorage.getItem('cookies') || cookiesAccepted){
        setShowBanner(false);
        localStorage.setItem("cookies",'1');
      }
  }, [cookiesAccepted]) //Estados que al modificar ejecutarán el código en esta función

  const handleAcceptCookies = () => {
    // Guardar el consentimiento de las cookies en el estado, cookies o en tu lógica de gestión de cookies
    setShowBanner(false);
    localStorage.setItem("cookies",'1');
  };

  const handleDimiss = () =>{
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <Message
          floating
          onDismiss={handleDimiss}
        >
          <Segment raised>
            <Label as='a' color='green' ribbon style={{ position: 'absolute', top: 12, left: -14 }}>
              Privacidad
            </Label>
            <span><b>Uso de cookies</b></span>
            <p>Utilizamos cookies tan solo para mejorar tu experiencia en el sitio. Si no las aceptas, podrás navegar igual, pero con posibles problemas de visualización y sin la informacón extendida que te da el aceptar las cookies,</p>
            <p>¿Aceptas el uso de cookies?</p> 
            <Button
              primary
              content="Si"
              onClick={handleAcceptCookies}
            />
            <Button
              secondary
              content="No"
              onClick={handleDimiss}
            />            
          </Segment>
        </Message>
      )}
    </>
  );
};

export default Banner_cookies;