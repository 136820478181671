import React, { useEffect, useState, useRef } from 'react';
import { Button, Dropdown,Table, Input,Popup } from "semantic-ui-react";
import {url_base} from "./funciones.js"

import TablaNutsAlimento from './TablaNutsAlimento.jsx';
import { json } from 'react-router-dom';

const VistaNutsAlimentos = ({buscaAlimento,setModal,setDatosAlimento,manual,index}) => { 
    const [lst_alimentos,set_lst_alimentos]=useState([]);
    const [alimento, set_alimento]=useState(buscaAlimento.current);
    const [introMan,setIntroMan]=useState(false);
    const rows = ["Fatty acids, total trans","Sodium, Na","Total lipid (fat)",
    "Protein","Energy","Water","Carbohydrate, by difference"];   
    const [pesos, setPesos] = useState(Array(rows.length).fill('')); // Estado para almacenar los pesos
    const [inputPeso, setInputPeso] = useState(100);
    const [pesoValue, setPesoValue] = useState(inputPeso);
    const [errorPeso,setErrorPeso]=useState(false);
    
    useEffect(() => {
    }, []); //Variable que con el cambio de esta, quiere que lance evento

    async function seriesSuggestions(string) {
        // Function gets a string and returns a list of series based on the string
        // This is for the search function
        //Realiza consulta para ver las palabras que empiezan por lo que se esta introduciendo
        let url = url_base();
        await fetch(url+`API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
            query_alimento: string.trim(),
            }),
            headers: {
            Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
        })   
        .then(response => response.json())
        .then(res => {
            let list=res.results;
            //Publica el listado de recetas
            if (typeof list !='undefined'){
                //Crea la lista de opciones, que son las palabras encontradas
                let options_lst = list.map((x) => (
                {key: x, text: x, value: x}
                ));
                //Establece en el cuadro de búsqueda, las palabras encontradas
                set_lst_alimentos(options_lst);
            }
        });
    }

    const handleSearchChangeAlimento = (e, { searchQuery }) => {
        buscaAlimento.current=searchQuery;
        // Realiza la sugerencia de palabras que empiezan por searchQuery
        seriesSuggestions(searchQuery);
    };

    const handleKeyDownAlimento = (e) => {
        // Comprueba si se ha pulsado enter
        if (e.key === 'Enter') {
            // Realiza la búsqueda
            // Pone en la propiedad las palabras a buscar y cuando esta propiedad cambia,
            // en principal.jsx se ejecuta la función para realizar la búsqueda.
            set_alimento(buscaAlimento.current);
        }
    };

    const handleSelectAlimento = (e, data) => {
        const { value } = data.options.find((option) => option.text === data.value);
        buscaAlimento.current=value;
        set_alimento(value);
    };

    const handleGuardar = () => {
        const jsonData = {};
        const jsonDataNuts = {};
        //Si se le ha asignado peso lo introduce
        if(pesoValue){
            pesos[1]=pesoValue;
            setPesoValue(null);
        }
        rows.forEach((fila, index) => {
          jsonDataNuts[fila] = parseFloat(pesos[index+2]);
        });
        jsonData['receta']=pesos[0];
        jsonData['ingredientes']=[pesos[0]];
        jsonData['nutrientes']={};
        jsonData['nutrientes']['peso']=pesos[1];
        jsonData['nutrientes_basicos']=jsonDataNuts;
        //Almacena los valores y cierra el modal
        setDatosAlimento(jsonData,index,'picar');
        setModal(false);
    };

    const handleDataAlimentos = (datos) => {
        rows.forEach((fila, index) => {
            pesos[index+2] = (datos.nuts[fila]==undefined?0:datos.nuts[fila]);
        });
        pesos[0]=datos.alimento;
    };
        
    const handleCancelar = () => {
        setIntroMan(!introMan)
        setPesos(Array(rows.length).fill(''));
    };    

    const handlePesoChange = (e) => {
        setInputPeso(e.target.value);
    };

    const handlePesoConfirm = () => {
        const parsedValue = parseFloat(inputPeso);
        if (!isNaN(parsedValue) && inputPeso !== '') { // Verificar si es un valor numérico y no está vacío
            setPesoValue(inputPeso);
        }else{
            setErrorPeso(true);
        }
    };

    const handlePopupKeyDown = (e) => {
        if (e.key === 'Enter') {
          handlePesoConfirm();
        }
    };

    const IntroManual = () => {
    
      const handlePesoChange = (index, value) => {
        const newPesos = [...pesos];
        newPesos[index] = value;
        setPesos(newPesos);
      };
    
      return (
        <Table celled style={{ margin: 0, padding: 0 }}>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell style={{ margin: 0, padding: 0 }}>Nombre</Table.HeaderCell>
                <Table.HeaderCell style={{ margin: 0, padding: 0 }}>Peso</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
    
            <Table.Body>
            {rows.map((fila, index) => (
                <Table.Row 
                    key={index} 
                    style={{
                        margin: 0,
                        padding: 0,
                        backgroundColor: index < 2 ? 'lightgray' : 'transparent' // Fondo diferente para las dos primeras filas
                      }}                >
                <Table.Cell style={{ margin: 0, padding: 0 }}>{fila}</Table.Cell>
                <Table.Cell style={{ margin: 0, padding: 0 }}>
                    <Input
                    value={pesos[index]}
                    onChange={(e) => handlePesoChange(index, e.target.value)}
                    style={{
                        width: '100%',
                        padding: 0,
                        margin: 0,
                        border: 'none',
                        boxShadow: 'none',
                        borderRadius: 0,
                        background: 'transparent'
                      }}
                    />
                </Table.Cell>
                </Table.Row>
            ))}
            </Table.Body>
        </Table>
      );
    }

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {setDatosAlimento && <Button primary onClick={handleGuardar}>Guardar</Button>}
                {(setDatosAlimento &&!introMan) &&      
                    <Input
                        placeholder={errorPeso?"Valor incorrecto, debe ser número":"Peso alimento gramos"}
                        value={inputPeso}
                        onChange={handlePesoChange}
                        onKeyDown={handlePopupKeyDown}
                    />
                }
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <Dropdown
                    id='input_search'
                    position='right'
                    icon='search'
                    placeholder={(buscaAlimento.current.length > 0) ? buscaAlimento.current : 'Alimento...'}
                    options={lst_alimentos}
                    search
                    selection
                    onSearchChange={handleSearchChangeAlimento}
                    onKeyDown={handleKeyDownAlimento}
                    onChange={handleSelectAlimento}
                    value={buscaAlimento.current}
                    />
                </div>
                {manual && <Button onClick={handleCancelar}>{introMan ? 'Cancelar' : 'Introducir Nutrientes'}</Button>}
            </div>
            {introMan ? <IntroManual /> : <TablaNutsAlimento esModal busca_al={alimento} nutrientes={handleDataAlimentos} peso={pesoValue} />}
        </div>
    );
}

export default VistaNutsAlimentos;
