import { Tab,Modal} from 'semantic-ui-react';
import React, { useEffect, useState, useRef } from 'react';
import {url_base} from "./funciones.js"
import './custom.css';

import Alimentos_nutriente from './alimentos_nutiente.jsx';

export default function TrabajarRecetas() {
    const [data,set_data]=useState({});
    const [modalAbierto, setModalAbierto] = useState(false);
    const [nut_consulta,set_nut_consulta]=useState('');
    
    useEffect(() => {
        recoge_beneficios();
    }, []); //Si esta vacio se ejecuta solo en el primer renderizado

    //Realiza la consulta para guarda la información nutriciona
    async function recoge_beneficios(){
        //Compone la cadena de búsqueda
        let url = url_base();
        await fetch(url + `API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
                beneficios : '-',
            }),
            headers: {
                Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
            })           
        .then(response => response.json())
        .then(res => {
            if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                set_data(res);
            }else{
                //ERROR, no lo ha podido procesar
            }  
        });
    }  

    const handleNutriente = (nut) => {
        set_nut_consulta(nut);
        setModalAbierto(true);
    };

    const TabContent = ({ categoryData }) => (
        <table>
            <thead>
            <tr>
                <th>Nutriente</th>
                <th>Valor</th>
                <th>Unidad</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(categoryData).map(([name, [value, unit]], index) => (
                <tr key={index}>
                <td onClick={() => handleNutriente(name)}>{name}</td>
                <td>{value}</td>
                <td>{unit}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );

    const panes = Object.keys(data).map((category, index) => ({
        menuItem: category,
        render: () => <Tab.Pane><TabContent categoryData={data[category]} /></Tab.Pane>,
    }));

    return (
        <div>
            <Tab panes={panes} menu={{ vertical: true, tabular: true  }} />
            {/* Modal */}
            <Modal open={modalAbierto} onClose={() => setModalAbierto(false)}>
                <Modal.Header>Alimentos con {nut_consulta} por 100 gr.</Modal.Header>
                <Modal.Content>
                    <Alimentos_nutriente nutriente= {nut_consulta}> </Alimentos_nutriente>
                </Modal.Content>
            </Modal>
        </div>
    );
}