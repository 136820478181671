import React, { useState, useEffect,useRef } from 'react';
import { Table, Segment, Header, Icon, Menu,Button, MenuItem,Modal,Dropdown } from 'semantic-ui-react';
import {url_base} from "./funciones.js"

import moment from 'moment';

import CalendarioCell from "./CalendarioCell.jsx";
import BotonLoadRec from './BotonLoadRec.jsx';
import VistaNutsAlimentos from './VistaNutsAlimento.jsx';

const daysOfWeek = ['Lunes', 'Martes', 'Miércoles','Jueves','Viernes','Sábado','Domingo' ];

export default function Calendario() {
  const [currentDate, setCurrentDate] = useState(moment());
  const todayIndex = currentDate.day();
  const daysToShow = daysOfWeek.slice(todayIndex,7).concat(daysOfWeek.slice(0,todayIndex))
  const displayedDays = daysToShow.slice(2, 7); // Tomar los últimos 5 días
  const [modal_Abierto, set_modal_Abierto] = useState(false);
  const [indexInModal,setIndexInModal]=useState(null);
  const [loadingData,setLoadingData]=useState(false);
  const [listaDatos, setListaDatos] = useState(Array(5).fill({
    desayuno: null, 
    almuerzo: null, 
    cena: null, 
    picar: null,
  }));
  const [selectedOtrosDatos,setSelectedOtrosDatos]=useState({"Fatty acids, total trans":false,"Sodium, Na":false,"Total lipid (fat)":false,"Protein":false,"Energy":false,"Water":false,"Carbohydrate, by difference":false})
  const buscaAlimento=useRef('');

  useEffect(() => {
    // Recuperar los datos de selectedOtrosDatos del localStorage si existen
    const savedSelectedOtrosDatos = JSON.parse(localStorage.getItem('selectedOtrosDatos_calendario'));

    if (savedSelectedOtrosDatos) {
      setSelectedOtrosDatos(savedSelectedOtrosDatos);
    }
  }, []);

  useEffect(() => {
    setLoadingData(true)
    //Recupera la información del calendario
    get_calendario(currentDate);
  }, [currentDate]);

  //Realiza la consulta para guarda la información nutriciona
  async function get_calendario(currentDate){
    //Compone la cadena de búsqueda
    let url = url_base();
    await fetch(url + `API/database.php`, { 
        method: 'POST',
        body: JSON.stringify({
            calendario_recetas_load: currentDate.clone().subtract(4, 'days'),
        }),
        headers: {
            Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
        },
        })           
    .then(response => response.json())
    .then(res => {
        if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
            //Publica el listado de recetas
            if ('status' in res){
                if(res.status==1){
                    //Datos recogidos correctamente
                    setListaDatos(res.contenido);
                }else{
                    //ERROR, no lo ha podido procesar
                }
            }else{
                //ERROR, no lo ha podido procesar
            }            
        }else{
            //ERROR, no lo ha podido procesar
        }  
    });
  }

  //Realiza la consulta para guarda la información nutriciona
  async function delete_data(datos,tipo,fecha){
    //Compone los datos a enviar
    const data = {
        datos: datos,
        tipo: tipo,
        fecha: fecha
      };
    //Compone la cadena de búsqueda
    let url = url_base();
    await fetch(url + `API/database.php`, { 
        method: 'POST',
        body: JSON.stringify({
            calendario_recetas_delete: data,
        }),
        headers: {
            Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
        },
        })           
    .then(response => response.json())
    .then(res => {
        if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
            //Publica el listado de recetas
            if ('status' in res){
                if(res.status==1){
                    //Borrado correcta
                }else{
                    //ERROR, no lo ha podido procesar
                }
            }else{
                //ERROR, no lo ha podido procesar
            }            
        }else{
            //ERROR, no lo ha podido procesar
        }  
    });
}

  const handleContenido = (contenido,index,tipo) =>{
    // Inicializa una lista vacía para datosLista
    let datosLista = listaDatos[index][tipo];
    // Cuando llegan nuevos datos en el objeto contenido
    const nuevosDatos = {
      nombre:contenido.receta,
      peso: contenido.nutrientes.peso,
      ingredientes: contenido.ingredientes,
      nutrientes: contenido.nutrientes_basicos       
    };
    // Si datosLista está vacía, inicialízalo con nuevosDatos
    if (!datosLista) {
      datosLista = [nuevosDatos];
    } else {
      // Agrega los nuevos datos a la lista datosLista
      datosLista.push(nuevosDatos);
    }
    // Crear una copia de la lista de datos
    const nuevaLista = [...listaDatos];
    // Actualizar el contenido en el índice indicado
    // Clonar el objeto antes de actualizarlo 
    nuevaLista[index] = {
      ...nuevaLista[index],
      [tipo]: datosLista
    };
    // Establecer la nueva lista de datos
    setListaDatos(nuevaLista);
    setLoadingData(false);
  } 

  const handleClearData = (index, tipo) => {
    //Borra los datos de la base de datos
    listaDatos[index][tipo].map((item) =>{
      delete_data(item,tipo,currentDate.clone().subtract(4-index, 'days'));
    })

    // Crear una copia de la lista de datos
    const nuevaLista = [...listaDatos];
    // Limpiar los datos en el índice y tipo especificados
    nuevaLista[index][tipo] = null;
    // Establecer la nueva lista de datos
    setListaDatos(nuevaLista);
  };

  const renderMenuDay = (day, index) => {
    const currentDay = currentDate.clone().subtract(4-index, 'days');
    const isToday = currentDay.isSame(moment(), 'day');

    // Obtener los datos de cada tipo para el día actual
    const desayunoData = listaDatos[index]['desayuno'];
    const almuerzoData = listaDatos[index]['almuerzo'];
    const cenaData = listaDatos[index]['cena'];
    const picaData = listaDatos[index]['picar'];

    // Calcular la suma de los datos para el día actual
    const sumaPesos = (
      (desayunoData ? desayunoData.reduce((totalPeso, item) => totalPeso + item.peso, 0):0) +
      (almuerzoData ? almuerzoData.reduce((totalPeso, item) => totalPeso + item.peso, 0):0) +
      (cenaData ? cenaData.reduce((totalPeso, item) => totalPeso + item.peso, 0):0)+
      (picaData? picaData.reduce((totalPeso, item) => totalPeso + item.peso, 0):0)
    );

    // Calcular las sumas individuales de otros datos seleccionados para el día actual
    const sumaOtrosDatos = {};

    Object.keys(selectedOtrosDatos).forEach((dato) => {
      if (selectedOtrosDatos[dato]) {
        let suma = 0;
        if (desayunoData){
          desayunoData.forEach((receta) => {
            if(receta.nutrientes !== undefined) {
              suma += receta.nutrientes[dato][0] || 0;
            }
          })
        }
        if (almuerzoData){
          almuerzoData.forEach((receta) => {
            if(receta.nutrientes !== undefined) {
              suma += receta.nutrientes[dato][0] || 0;
            }
          })
        }
        if (cenaData){
          cenaData.forEach((receta) => {
            if(receta.nutrientes !== undefined) {
              suma += receta.nutrientes[dato][0] || 0;
            }
          })
        }
        if (picaData){
          picaData.forEach((receta) => {
            if(receta.nutrientes !== undefined) {
              suma += receta.nutrientes[dato][0] || 0;
            }
          })
        }
        // Agrega más condiciones para otros tipos de comida si es necesario
        
        sumaOtrosDatos[dato] = suma;
      }
    });
    
    const handleIntroAlimento = (index) => {
      setIndexInModal(index);
      set_modal_Abierto(true);
      setLoadingData(false);
    }

    return (
      <Table.Row key={day} positive={isToday}>
        <Table.Cell>
          <Segment.Group>
            <Segment style={{ margin: '0', padding: '0px'}}>
              <Table>
                <Table.Body>
                  <Table.Row positive={isToday}>
                    <Table.Cell style={{width: '20%'}}>
                      <div>
                        {day}
                        {isToday && <span> (Hoy)</span>}
                      </div>
                      <div style={{ fontSize: '12px' }}>{currentDay.format('D MMM YYYY')}</div>
                    </Table.Cell>
                    <Table.Cell style={{width: '30%'}}>
                      <div>Resumen:</div>
                      <div>Pesos: {sumaPesos}</div>
                    </Table.Cell>
                    <Table.Cell style={{width: '50%'}}>
                      <div>
                        {Object.keys(sumaOtrosDatos).map((dato) => (
                          <div key={dato}>
                            {dato}: {sumaOtrosDatos[dato].toFixed(2)}
                          </div>
                        ))}
                      </div>            
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
            {["desayuno", "almuerzo", "cena","picar"].map((tipo) => (
              <Segment style={{ margin: '0', padding: '5px' }} key={tipo}>
                <div style={{display: 'flex'}}>
                  {listaDatos[index][tipo] && (
                    <>
                      {tipo.charAt(0).toUpperCase() + tipo.slice(1)}
                      {tipo=='picar'?(
                        <Button icon="plus" size="mini"onClick={() => handleIntroAlimento(index)}/>
                      ):(
                        <BotonLoadRec icon="plus" size="mini" contenido={(datos) => handleContenido(datos, index, tipo)} />
                      )}
                      <Button icon="close" size="mini" onClick={() => handleClearData(index, tipo)} />
                    </>
                  )}
                  {!listaDatos[index][tipo] && (
                      tipo=='picar'?(
                        <Button size="mini" onClick={() => handleIntroAlimento(index)}>{tipo.charAt(0).toUpperCase() + tipo.slice(1)}</Button>
                        ):(
                        <BotonLoadRec texto={tipo.charAt(0).toUpperCase() + tipo.slice(1)} size="mini" contenido={(datos) => handleContenido(datos, index, tipo)} />
                      )
                  )}
                </div>
                <div>
                  {listaDatos[index][tipo] && 
                    (
                    listaDatos[index][tipo].map((datos, subIndex) => (
                      <CalendarioCell
                        key={subIndex} // Asegura que cada componente tenga una clave única
                        datos={datos}
                        tipo={tipo}
                        fecha={currentDay.format('D MMM YYYY')}
                        loading={loadingData}
                        />
                    ))
                  )}
                </div>
              </Segment>
            ))}
          </Segment.Group>
        </Table.Cell>
      </Table.Row>
    );
  };

  const handlePrevDays = (days) => {
    setLoadingData(true)
    setCurrentDate(currentDate.clone().subtract(days, 'days'));
  };

  const handleNextDays = (days) => {
    setLoadingData(true)
    setCurrentDate(currentDate.clone().add(days, 'days'));
  };

  const handleOtrosDatosChange = (selectedValues) => {
    const newSelectedOtrosDatos = { ...selectedOtrosDatos };

    // Iterar a través de los nombres de datos y actualizar el estado
    Object.keys(newSelectedOtrosDatos).forEach(dato => {
      newSelectedOtrosDatos[dato] = selectedValues.includes(dato);
    });
  
    setSelectedOtrosDatos(newSelectedOtrosDatos);
    // Almacenar los datos de selectedOtrosDatos en el localStorage cuando cambien
    localStorage.setItem('selectedOtrosDatos_calendario', JSON.stringify(newSelectedOtrosDatos));
  };

  return (
    <div>
        <Header as="h1">Calendario Semanal</Header>
        <Menu>
          <Menu.Item>
            <Icon name="angle left" onClick={() => handlePrevDays(5)} />
            {currentDate.format('MMMM YYYY')}
            <Icon name="angle right" onClick={() => handleNextDays(5)} />
            </Menu.Item>
            <MenuItem>
              <Dropdown 
                placeholder='Datos en resumen' 
                fluid 
                multiple selection 
                options={Object.keys(selectedOtrosDatos).map(dato => ({
                  key: dato,
                  text: dato,
                  value: dato,
                }))} 
                value={Object.keys(selectedOtrosDatos).filter(dato => selectedOtrosDatos[dato])} // Establecer elementos seleccionados
                onChange={(event, data) => handleOtrosDatosChange(data.value)}
              />
          </MenuItem>
        </Menu>
        <Table celled structured loading={loadingData}>
          <Table.Body>
              {displayedDays.map((day, index) => renderMenuDay(day, index))}
          </Table.Body>
        </Table>
            {/* Modal */}
            <Modal open={modal_Abierto} onClose={() => set_modal_Abierto(false)}>
                <Modal.Header>Introduccion de alimentos </Modal.Header>
                <Modal.Content>
                    {/* Renderiza el componente VistaNutsAlimentos dentro del modal */}
                    <VistaNutsAlimentos buscaAlimento={buscaAlimento} manual={true} setModal={set_modal_Abierto} setDatosAlimento={handleContenido} index={indexInModal}/>
                </Modal.Content>
            </Modal>
    </div>
  );
}
