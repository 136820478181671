import React, { useEffect, useRef, useState } from 'react';
import { Image, Table, Icon, Popup,Menu,Divider } from 'semantic-ui-react'
import {url_base} from "./funciones.js"

import Caracteristicas from './caracteristicas.jsx';

import img_triste from '../imagenes/alimentos/triste.jpeg'

import './custom.css';

export default function ListadoCell(props) {
    // Verificar si datos_server es null y proporcionar un valor predeterminado si es necesario
    const megusta = props.datos_server?.megusta ?? [];
    // Estado local para mantener los corazones
    const [corazonesEstado, setCorazonesEstado] = useState(megusta);

    useEffect(() => {
        //Al volver de cambiar la pantalla, se posiciona en la receta que se ha pulsado
        const elementoEnfocar = document.getElementById(props.pos_id);
        if (elementoEnfocar) {
            elementoEnfocar.scrollIntoView();  
        } 
    }, [props.pos_id]) //Estados que al modificar ejecutarán el código en esta función

    async function set_red_hearts(estado, idx){
        //Llama al API para actualizar las recetas que le gustan
        let url = url_base();
        await fetch(url+`API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
              megusta: estado,
              id: idx,
            }),         ///Authorization
            headers: {
              Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
          })   
          .then(response => response.json())
          .then(res => {
            //Cambiado estado
          });
    }

    // Función para manejar el clic en el corazón
    const handleheartClick = (idx) => {
        // Crear una copia del estado actual de los corazones
        const nuevosCorazones = [...corazonesEstado];
        // Cambiar el estado del corazón en la posición index
        nuevosCorazones[idx] = !nuevosCorazones[idx];
        // Actualizar el estado local
        setCorazonesEstado(nuevosCorazones);
        //Pone el estado del corazon
        props.datos_server.megusta[idx]=!props.datos_server.megusta[idx];
        //Pone en la BD el estado del corazón
        set_red_hearts(props.datos_server.megusta[idx],props.datos_server.ids[idx]);
    };

    //Crea el link a la página web en la que también incorpora el texto que ha de resaltar
    const crea_link = (i,r) =>{
        var texto='';
        for (const ind in r.ingredientes_originales){
            texto+='text='+r.ingredientes_originales[ind].replace(/,/g, "%2C")+'&';
        }
        return i.link+'#:~:'+texto //#:~:text=
    }

    //Crea un menú emergente para información y utilidades de la receta
    const menu_receta = (indx,ingredientes,alimentos) => {
        return (
            <Popup
                key={'menu_'+indx}
                trigger={<Icon name='bars' size='large' />}
                on='click'
                position='bottom right'
                content={
                <Menu secondary vertical size='small'>
                    <Menu.Item 
                        name='Ingredientes' 
                        onClick={() => props.handleItemClick([indx,ingredientes,alimentos],0)}
                    />
                    <Menu.Item 
                        name='Nutrientes' 
                        onClick={() => props.handleItemClick(indx,1)}
                    />
                    <Menu.Item 
                        name='Registrar un problema' 
                        onClick={() => props.handleItemClick(indx,2)}
                    />
                </Menu>
                }
            />
        )
      }
    var listado = <div> </div>;
    var filas= <div></div>;

   
    if (props.datos_server!==null) {
        //Recoge las opciones
        if (props.datos_server.status===0){
            //No ha encontrado nada. Muestra mensaje de no encontrado
            filas=  (<div key='none'>
                        <Table.Row style = {{ verticalAlign: 'middle' }} >
                            <Table.Cell rowSpan = '2' > < Image avatar src = {img_triste}size = { 'tiny' }style = {{ position: 'initial', border: '0px' }}/></Table.Cell >
                            <Table.Cell  style = {{ border: '0px', padding: '0px' }}>Lo sentimos, no hemos encontrado ninguna receta con estas características</Table.Cell >
                        </Table.Row> 
                    </div>)
            listado= (< Table collapsing style = {{ margin: '0px', paddingTop: '50px', borderSpacing: '3px' }} > 
                        <Table.Body > { filas } </Table.Body> 
                     </Table> )
        }else{
            //Muestra el listado de las recetas encontradas
            filas = props.datos_server.info.map((i,index) => {
                return (
                     <div key={i.titulo}  id={props.datos_server.ids[index]}>
                        <Table.Row style = {{ verticalAlign: 'middle' }} >
                            <Table.Cell  style = {{ verticalAlign: 'middle'}}> 
                                    <div  align="left" style={{width:'20%' , display:'inline-block'}}>
                                        < Image avatar src = { i.imagen } ui={false} className='img-list-size' style = {{ position: 'initial', border: '0px' }}/>
                                    </div>
                                    <div  align="right" style={{width:'10%' , display:'inline-block'}}>
                                        {props.datos_server.receta[index].calorias} Kcal ración
                                    </div>
                                    <div  align="right" style={{width:'10%' , display:'inline-block'}}>
                                        <Icon
                                            name={props.datos_server.megusta[index] ? 'heart' : 'heart outline'}
                                            color={props.datos_server.megusta[index] ? 'red' : 'black'}
                                            onClick={() => handleheartClick(index)}
                                        />                                                  
                                    </div>
                                    <Caracteristicas width= '60%' datos={props.datos_server.info[index].caracteristicas}></Caracteristicas>
                            </Table.Cell >
                        </Table.Row> 
                        <Table.Row style = { { padding: '0px' }} >
                            <Table.Cell style = { { borderTop: '0px' }} > 
                                <span
                                style={{'display': 'inline-block', 'width': '80%'}}
                                >
                                    < a href = { crea_link(i,props.datos_server.receta[index]) } target = "_blank" > { i.titulo } </a>
                                </span>
                                <span
                                style={{'display':'inline-block', 'width':'20%', 'textAlign':'right'}}
                                >
                                    {menu_receta(props.datos_server.ids[index],props.datos_server.receta[index].ingredientes_originales,props.datos_server.receta[index].alimentos)}
                                </span>
                            </Table.Cell>
                        </Table.Row> 
                        <Table.Row style = { { padding: '0px' }} >
                            <Table.Cell  style = { { borderTop: '0px'}}> { i.resumen } </Table.Cell> 
                        </Table.Row > 
                        <Divider style={{margin:'0px'}} />
                    </div>                        
                );
            });
            //Muestra las tabla de las recetas o bien los nutrientes de una receta
            listado = ( 
                < Table unstackable collapsing style = {{ margin: '0px', paddingTop: '0px'}} > 
                    <Table.Body > 
                        { filas } 
                    </Table.Body> 
                </Table >
            );
        }
    }

    return listado;
}