import {React,useState} from 'react';
import { Accordion,Menu, Dropdown,Icon } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom'

import Menu_user from './menu_user.jsx';
import Menu_filtros from './menu_filtros.jsx';

function Menu_principal(props) {
    const navigate = useNavigate()
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleClick = (index) => {
      setActiveIndex(activeIndex === index ? -1 : index);
    };

    const handleLogin = (e) =>{
        navigate('/login');
    }
    const handleInicio = (e) =>{
        navigate('/');
    }    
    const handleEvalua_receta = (e) =>{
        navigate('/logout');
    }
    const handleIntro_receta = (e) =>{
        navigate('/receta');
    }

    return (
        <div>
            {props.visible && <Menu vertical inverted>
                <Dropdown.Item item text="Inicio" onClick={handleInicio} style={{ textAlign: 'left' }}>
                </Dropdown.Item>
                <Dropdown item text="Usuario">
                    <Dropdown.Menu>
                        <Menu_user></Menu_user>
                    </Dropdown.Menu>
                </Dropdown>
                <Accordion inverted as={Menu} vertical>
                    <Menu.Item>
                        <Accordion.Title
                            active={activeIndex === 0}
                            index={0}
                            onClick={() => handleClick(0)}
                            style={{ fontSize: '15px', fontWeight: 'bold' ,textAlign: 'left'}}
                            >
                            <Icon name="dropdown" />
                            Filtros
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === 0}>
                            <Menu_filtros handleView={props.handleView} datos_server={props.datos_server}></Menu_filtros>
                        </Accordion.Content>
                    </Menu.Item>
                </Accordion>
                <Dropdown item text="Recetas">
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={handleIntro_receta}
                        >Introducir receta</Dropdown.Item>
                        <Dropdown.Item
                            onClick={handleEvalua_receta}
                        >Evaluar receta</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>}
        </div>
    );

}

export default Menu_principal;