import React, { useEffect, useState } from 'react';
import { Table,Button,Icon,Message,Modal } from 'semantic-ui-react'
import { useNavigate, useLocation } from 'react-router-dom'
import {url_base} from "./funciones.js"

import Alimentos_nutriente from './alimentos_nutiente.jsx';

export default function TablaNutsReceta(props) {
    const cookies=localStorage.getItem('cookies')==='1'?true:false;
    const location = useLocation();
    const selected_id=location.state?location.state.selected_id:null;
    const navigate = useNavigate()
    const calculada=location.state?'info' in location.state:true;
    const [lst_nuts,set_lst_nuts]=useState(location.state?'info' in location.state?location.state:[]:null);
    const en_trabajo=props.datos?true:false;
    const [completa,set_completa]=useState(false); //calculada || en_trabajo);
    const [modalAbierto, set_modalAbierto] = useState(false);
    const [nut_consulta,set_nut_consulta]=useState('');

    useEffect(() => {
        // eslint-disable-next-line
        if (!calculada){
            do_search()
        }
    }, [completa]) //Estados que al modificar ejecutarán el código en esta función
  
    //Realiza la consulta para obtener la información nutriciona
    async function do_search(){
        let url = url_base();
        await fetch(url+`API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
                nutrientes: selected_id,
                completa: completa,
            }),
            headers: {
              Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
          })   
        .then(response => response.json())
        .then(res => {
            //Publica el listado de recetas
            set_lst_nuts(res);
        });
    }

    const handleClose = () => {
        if(!en_trabajo){
            navigate(-1);
        }
    };

    const handleMas = () => {
        set_completa(!completa);
    };

    const handleNutriente = (nut) => {
        set_nut_consulta(nut)
        set_modalAbierto(true);
    };

    let filas=<Table.Row></Table.Row>
    //Asignado aquí props.datos, porque da problema en la signación de lst_nuts, siempre devolvia null
    //lst_nuts, vienen los datos de la base de datos
    //props.datos.nutrientes, vienen los datos desde la llamada
    let lst=lst_nuts;
    let pesoRacion=300;
    if(!lst){
        lst=props.datos.nutrientes
        pesoRacion=props.pesoRacion?(props.pesoRacion==-1 && props.datos)?props.datos.nutrientes?props.datos.nutrientes.peso:0:props.pesoRacion:3;
    }

    if (lst && Object.keys(lst).length>1){
        let nueva_propiedad = {
            nutrientes_basicos: {}, // Inicializamos como un objeto vacío
        };
        let existe_nutb=false;
        filas= Object.keys(lst.info).map((key) => {
            // Si todos es true, mostramos todas las filas
            // Si todos es false, solo mostramos las filas donde lst.info[key][3] contiene 'otros'
            const reducida=(!en_trabajo && !calculada) || (lst.info[key][3].indexOf('otros') !== -1)
            const peso=((lst.info[key][0]/300)*pesoRacion);
            const cdr=props.pesoRacion?peso/lst.info[key][1]:lst.info[key][2];
            //Recoge los datos los nutrientes básicos calculados para la escala que se indica en racion
            //Siempre que se haya pasado racion
            if (props.pesoRacion && (lst.info[key][3].indexOf('otros') !== -1) && !existe_nutb){
                //Comprueba si ya existen los nutrientes básicos y si tienen el mismo valor
                if (props.datos.nutrientes_basicos){
                    if(props.datos.nutrientes_basicos[key][0]===parseFloat(peso.toFixed(2))){
                        existe_nutb=true;
                    }
                }
                const nuts_basic = {
                    [key]: [parseFloat(peso.toFixed(2)),lst.info[key][1],parseFloat(cdr.toFixed(2)),'']
                };
                // Agregamos el objeto nuts_basic al objeto nutrientes_basicos de nueva_propiedad
                Object.assign(nueva_propiedad.nutrientes_basicos, nuts_basic);   
            }
            if (completa || (!en_trabajo && !calculada) || reducida) {
              return (
                <Table.Row key={key}>
                  <Table.Cell onClick={() => handleNutriente(key)}>{key}</Table.Cell>
                  <Table.Cell>{parseFloat(peso).toFixed(reducida?2:6)}</Table.Cell>
                  <Table.Cell>{lst.info[key][1]}</Table.Cell>
                  <Table.Cell>{parseFloat(cdr).toFixed(2)}</Table.Cell>
                  <Table.Cell>{lst.info[key][3].join()}</Table.Cell>
                </Table.Row>
              );
            } else {
              // Si no se cumple la condición, retornamos null para que no se muestre esta fila
              return null;
            }
        });
        // Introduce en los datos los nutrientes básicos calculados para la escala que se indica en racion
        //Siempre que se haya pasado racion
        if (props.pesoRacion && !existe_nutb){
            props.setDatos({ ...props.datos, ...nueva_propiedad });
        }
    }
    
    let tabla=(
        <Table celled  structured unstackable={!en_trabajo} size={'small'}>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell textAlign='center' colSpan='3'>
                    <Button 
                        size='large'
                        icon 
                        labelPosition='left'
                        onClick={handleClose}>
                        {!en_trabajo && <Icon name='log out' />}
                        Nutrientes mas significativos para una ración de {pesoRacion?(pesoRacion).toFixed(0):0}g
                    </Button>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='left' colSpan='2'>
                    <Button
                        icon={completa?'minus':'plus'}
                        color='green'
                        position='left'
                        onClick={handleMas}
                    ></Button>
                </Table.HeaderCell>
            </Table.Row>               
            <Table.Row key='head'>
                <Table.HeaderCell>Nutriente</Table.HeaderCell>
                <Table.HeaderCell>gramos</Table.HeaderCell>
                <Table.HeaderCell>CDR</Table.HeaderCell>
                <Table.HeaderCell>% CDR</Table.HeaderCell>
                <Table.HeaderCell>beneficios</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
                {filas}
            </Table.Body>
            <Table.Footer>
            {!cookies && 
                <Table.Row>
                    <Table.HeaderCell colSpan="5">
                        <Message info>
                            <Message.Header>Cookies</Message.Header>
                            <p>Al no tener las cookies activadas, al volver atrás vuelve al inicio de la consulta</p>
                        </Message>    
                    </Table.HeaderCell>
                </Table.Row>
            }
            </Table.Footer>
            {/* Modal */}
            <Modal open={modalAbierto} onClose={() => set_modalAbierto(false)}>
                <Modal.Header>Alimentos con {nut_consulta} por 100 gr.</Modal.Header>
                <Modal.Content>
                    <Alimentos_nutriente en_trabajo={en_trabajo} nutriente= {nut_consulta} receta={props.datos?props.datos.receta:null}> </Alimentos_nutriente>
                </Modal.Content>
            </Modal>
        </Table>
        );

    return tabla;
}