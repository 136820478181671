import React, { useEffect, useState } from 'react';
import { Label,Input,Form,Checkbox,Button, TextArea,Message } from 'semantic-ui-react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {url_base} from "./funciones.js"

export default function Problemas() {
    const cookies=localStorage.getItem('cookies')==='1'?true:false;
    const location = useLocation();
    const selected_id=location.state.selected_id;
    const navigate = useNavigate()
    const [chk_calorias,set_chk_calorias]=useState(false);
    const [chk_direccion,set_chk_direccion]=useState(false);
    const [chk_filtro,set_chk_filtro]=useState(false);
    const [chk_nutrientes,set_chk_nutrientes]=useState(false);
    const [chk_ingredientes,set_chk_ingredientes]=useState(false);
    const [chk_otros,set_chk_otros]=useState(false);
    const [otros,set_otros]=useState('');
    const [description,set_description]=useState('');

    useEffect(() => {
        // eslint-disable-next-line
    }, []) //Estados que al modificar ejecutarán el código en esta función

    function handleTextAreaChange(event) {
        const { value } = event.target;
        set_description(value);
      }
  
    //Realiza la consulta para obtener la información nutriciona
    async function do_send(){
        let tipo='';
        //Identifica el problema
        if(chk_calorias){
            tipo='calorias'
        }else if(chk_direccion){
            tipo='direccion'
        }else if(chk_filtro){
            tipo='filtro'
        }else if(chk_nutrientes){
            tipo='nutrientes'
        }else if(chk_ingredientes){
            tipo='ingredientes'
        }else{
            tipo=otros
        }
        //Compone la cadena de envio
        const datos = {
            problema: selected_id,
            tipo: tipo,
            desc: description
          };
        let url = url_base();
        await fetch(url+`API/database.php`, { 
            method: 'POST',
            body: JSON.stringify(datos),
            headers: {
              Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
          })       
        .then(response => {
            //Sale de la pantalla
            alert('Muchas gracias por tu colaboración.')
            navigate(-1)
        });
    }
    const handleClose = () => {
      navigate(-1);
    };

    const handleSend = () => {
        do_send()
    };
    
    let pantalla=(
        <Form
            style={{'paddingLeft': '20px','paddingRight': '20px', 'textAlign':'left'}}
            >
            <Form.Field/>
            <Form.Field>
                <Checkbox
                    fluid={true}
                    label='Calorias'
                    onChange={(e, data) => set_chk_calorias(data.checked)}
                    checked={chk_calorias}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    label='Direccionamiento'
                    onChange={(e, data) => set_chk_direccion(data.checked)}
                    checked={chk_direccion}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    fluid={true}
                    label='Mal filtrada'
                    onChange={(e, data) => set_chk_filtro(data.checked)}
                    checked={chk_filtro}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    fluid={true}
                    label='Nutrientes'
                    onChange={(e, data) => set_chk_nutrientes(data.checked)}
                    checked={chk_nutrientes}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    fluid={true}
                    label='Ingredientes'
                    onChange={(e, data) => set_chk_ingredientes(data.checked)}
                    checked={chk_ingredientes}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    fluid={true}
                    label='Otros'
                    onChange={(e, data) => set_chk_otros(data.checked)}
                    checked={chk_otros}
                />
                <Input 
                    onChange={(e, data) => set_otros(data.value)}
                    placeholder='Tipo otros...' 
                />
            </Form.Field>
            <Form.Field/>
            <Form.Field>
                <Label pointing='below'>Por favor, entre una breve descripción del problema</Label>
                <TextArea 
                    onChange={(e, data) => set_description(data.value)}
                    fluid 
                    placeholder='Breve descripción...'
                />
            </Form.Field>
            <Form.Field>
            {!cookies && 
                <Message info>
                    <Message.Header>Cookies</Message.Header>
                    <p>Al no tener las cookies activadas, al volver atrás vuelve al inicio de la consulta</p>
                </Message>    
            }
            </Form.Field>
            <Form.Field>
                <Button primary onClick={handleSend}>Enviar</Button>
                <Button secondary onClick={handleClose} >Cancelar</Button>
            </Form.Field>
        </Form>
        );

    return pantalla;
}