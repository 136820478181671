import React, { useState, useEffect } from "react";
import { Table,Icon } from "semantic-ui-react";
import {url_base} from "./funciones.js"

export default function CalendarioCell({ datos,tipo,fecha,loading }) {
    const [showData, setShowData] = useState(true);

    useEffect(() => {
        setShowData(true);
        if(!loading){
            save_data(datos,tipo,fecha);
        }
    }, []); //Variable que con el cambio de esta, quiere que lance evento

    //Realiza la consulta para guarda la información nutriciona
    async function save_data(datos,tipo,fecha){
        //Compone los datos a enviar
        const data = {
            datos: datos,
            tipo: tipo,
            fecha: fecha
          };
        //Compone la cadena de búsqueda
        let url = url_base();
        await fetch(url + `API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
                calendario_recetas_save: data,
            }),
            headers: {
                Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
            })           
        .then(response => response.json())
        .then(res => {
            if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                //Publica el listado de recetas
                if ('status' in res){
                    if(res.status==1){
                        //Introducción correcta
                        setShowData(true); // Muestra los datos
                    }else{
                        //ERROR, no lo ha podido procesar
                        setShowData(false); // Oculta los datos
                    }
                }else{
                    //ERROR, no lo ha podido procesar
                    setShowData(false); // Oculta los datos
                }            
            }else{
                //ERROR, no lo ha podido procesar
                setShowData(false); // Oculta los datos
            }  
        });
    }

    return(
        <>
            {datos && showData &&
                <Table.Row>
                    <Table.Cell style={{ width: '5%' }}>
                        {datos.tipo === 'carpeta' ? <Icon name="folder" /> : <Icon name="file" />}
                    </Table.Cell>
                    <Table.Cell style={{ width: '10%' }}>{datos.nombre}</Table.Cell>
                    <Table.Cell style={{ width: '5%' }}>{datos.peso} gr</Table.Cell>
                    <Table.Cell style={{ width: '30%' }}>{JSON.stringify(datos.ingredientes)}</Table.Cell>
                    <Table.Cell style={{ width: '50%' }}>{JSON.stringify(datos.nutrientes)}</Table.Cell>
                </Table.Row>
            }
        </>
    )
}