
import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Icon,Message } from "semantic-ui-react";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {url_base} from "./funciones.js"

import './custom.css';

export default function ListaIngredientes() {
    const cookies=localStorage.getItem('cookies')==='1'?true:false;
    const location = useLocation();
    const navigate = useNavigate()
    const [ingredientes, set_ingredientes] = useState(location.state.selected_id[1]);
    const [alimentos, set_alimentos] = useState(location.state.selected_id[2]);
    const selected_id=location.state.selected_id[0];
    const [mode_edit,set_mode_edit]=useState(false);

    useEffect(() => {
    }, []); //Si esta vacio se ejecuta solo en el primer renderizado

    const handleNutrientes = () => {
        navigate("/TablaNutsReceta",{ state: { selected_id } });
    };

    const handleClose = () => {
        if(mode_edit){
            set_mode_edit(false);
        }else{
            navigate(-1);
        }
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const newRows = [...ingredientes];
        newRows[index] = value;
        set_ingredientes(newRows);
    };

    const handleAddRow = () => {
        set_ingredientes([...ingredientes, ""]);
    };

    const handleDeleteRow = (index) => {
        const newRows = [...ingredientes];
        newRows.splice(index, 1);
        set_ingredientes(newRows);
    };

    const handleSave = () => {
        const dataToSend = { lineas: ingredientes };
        console.log(JSON.stringify(dataToSend));
    };

    const handleEdit = () => {
        set_mode_edit(!mode_edit);
    };

    return (
        <Table celled unstackable={true} size={'small'}>
            <Table.Header>
            <Table.Row  id={'id0'}>
                <Table.HeaderCell>Ingrediente</Table.HeaderCell>
                <Table.HeaderCell>Alimento</Table.HeaderCell>
                {mode_edit &&
                    <Table.HeaderCell>
                    <Button icon onClick={handleAddRow}>
                        <Icon name="plus" />
                    </Button>
                    </Table.HeaderCell>
                }
            </Table.Row>
            </Table.Header>

            <Table.Body>
            {ingredientes.map((row, index) => (
                <Table.Row key={index}>
                <Table.Cell className="ui celled table cell-space">
                    <input
                    className="input-full-cell"
                    type="text"
                    name="ingrediente"
                    value={row}
                    onChange={(e) => handleInputChange(e, index)}
                    />
                </Table.Cell>
                <Table.Cell className="ui celled table cell-space">
                    {alimentos[index]?alimentos[index]:''}
                </Table.Cell>
                {mode_edit &&
                <Table.Cell>
                    <Button icon  size='mini' onClick={() => handleDeleteRow(index)}>
                        <Icon name="trash alternate" />
                    </Button>
                </Table.Cell>
                }
                </Table.Row>
            ))}
            </Table.Body>

            <Table.Footer>
            {!cookies && 
                <Table.Row>
                    <Table.HeaderCell colSpan="2">
                        <Message info>
                            <Message.Header>Cookies</Message.Header>
                            <p>Al no tener las cookies activadas, al volver atrás vuelve al inicio de la consulta</p>
                        </Message>    
                    </Table.HeaderCell>
                </Table.Row>
            }
            <Table.Row>
                <Table.HeaderCell colSpan="2">
                {/*!mode_edit && <Button primary onClick={handleEdit}>
                    Editar
                </Button>
        */}
                {/*mode_edit && <Button primary onClick={handleSave}>
                    Save
                </Button>
    */}
                <Button secondary onClick={handleClose}>
                    {mode_edit?'Cancelar':'Salir'}
                </Button>
                {!mode_edit && <Button floated='right' onClick={handleNutrientes}>
                    Ver Nutrientes
                </Button>}
                </Table.HeaderCell>
            </Table.Row>
            </Table.Footer>
        </Table>
        );  
    }