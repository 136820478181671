import React, { useState,useEffect,useRef } from "react";
import { Button, Modal }  from "semantic-ui-react";

import FileExplorer from './FileExplorer.jsx';

export default function BotonLoadRec(props){
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      {props.icon? 
        (<Button icon={props.icon} size={props.size} onClick={() => setModalOpen(true)} />):
        (<Button size={props.size} onClick={() => setModalOpen(true)}>{props.texto?props.texto:"Recetas"}</Button>)
      }
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content>
          <FileExplorer contenido={props.contenido} ventana={setModalOpen} ruta={props.ruta}/>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setModalOpen(false)}>Cerrar</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
