import React, {useEffect, useRef, useState} from 'react';
import { Popup,Dropdown,Menu,Grid,Icon,Image, List,Header,Button } from 'semantic-ui-react'
import { Link, useNavigate } from 'react-router-dom'
import {url_base} from "./funciones.js"

import img_carne_roja from '../imagenes/alimentos/carne_roja.jpg';
import img_carne_blanca from '../imagenes/alimentos/carne_blanca.jpg'
import img_pescado from '../imagenes/alimentos/pescado.jpg'
import img_embutido from '../imagenes/alimentos/embutido.jpeg'
import img_verduras from '../imagenes/alimentos/verduras.jpg'
import img_pasta from '../imagenes/alimentos/pasta.png'
import img_legumbres from '../imagenes/alimentos/legumbres.png'
import img_cereales from '../imagenes/alimentos/cereales.png'
import img_frutas from '../imagenes/alimentos/frutas.jpeg'
import img_postres from '../imagenes/alimentos/postres.png'

import img_contento from '../imagenes/alimentos/contento.jpeg'
import img_cansado from '../imagenes/alimentos/cansado.jpeg'
import img_enfadado from '../imagenes/alimentos/enfadado.jpeg'
import img_estresado from '../imagenes/alimentos/estresado.jpeg'
import img_triste from '../imagenes/alimentos/triste.jpeg'

import Menu_user from '../menu/menu_user.jsx';
import './custom.css';

export default function Inicio(props){
    const navigate = useNavigate()
    const[sel_alimento,setSel_alimento]=useState([]);
    const[sel_estado,setSel_estado]=useState([]);
    const[cargando,set_cargando]=useState(false);
    const[options,set_options]=useState([]);

    const f_alimentos=[img_carne_roja,img_carne_blanca,img_pescado,img_embutido,img_verduras,img_pasta,img_cereales,img_legumbres,img_frutas,img_postres];
    const n_alimentos=['carne roja','carne blanca','pescado','embutido','verduras','pasta','cereales','legumbres','frutas','postres'];

    const f_estados=[img_contento,img_triste,img_cansado,img_enfadado,img_estresado];
    const n_estados=['contento','triste','cansado','enfadado','estresado'];

    const pal_busca=useRef('');

    //Pone los colores por defecto de la selección de los iconos
    if (sel_alimento.length===0){
        let cadena=[];
        for(let i=0;i<10;i++){
            cadena.push(false);
        }
        setSel_alimento(cadena);
        setSel_estado(cadena.slice(0,5))
    }

    useEffect(() => {
        //Detecta la pulsación de enter
        const keyDownHandler = e => {
          //Comprueba si se ha pulsado enter
          if (e.key === 'Enter'){
            //Envia la palabra almacenada en buscar, ya que al pulsar enter, e.target.value se borra
            //Realiza la búsqueda
            //Pone en la propiedad las palabras a buscar y cuando esta propiedad cambia,
            //en principal.jsx se ejecuta la función para realizar la busqueda.
            handleSubmit(); 
          }
        };
        //Asocia el evento para las entradas de teclado
        document.addEventListener('keydown', keyDownHandler);
        //Asocia el evento para las entradas de teclado, incluyendo para móvil
        const inputElement = document.querySelector('input');
    
        inputElement.addEventListener('input', (event) => {
          const value = event.target.value;
          pal_busca.current=value;
          //Realiza una sugerencia de palabras que empiezan por
          seriesSuggestions(value);
        });
        //Quita el evento de la función para evitar repetitibilidad
        return () => {
          document.removeEventListener('input', keyDownHandler);
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, []); //Variable que con el cambio de esta, quiere que lance evento

    async function seriesSuggestions(string) {
        // Function gets a string and returns a list of series based on the string
        // This is for the search function
        //const textURI = encodeURIComponent(string.trim());
        //Realiza consulta para ver las palabras que empiezan por lo que se esta introduciendo
        let url = url_base();
        //fetch(url+`API/database.php?query=${textURI}`)
        await fetch(url+`API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
              query: string.trim(),
            }),
            headers: {
              Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
          })       
        .then(response => response.json())
        .then(res => {
            let list=res.results;
            //Publica el listado de recetas
            if (typeof list !='undefined'){
                //Crea la lista de opciones, que son las palabras encontradas
                let options_lst = list.map((x) => (
                {key: x, text: x, value: x}
                ));
                //Establece en el cuadro de búsqueda, las palabras encontradas
                set_options(options_lst);
            }
        });
    }
    const handleAlimento = (e) => {
        let newArr = [...sel_alimento]; // copying the old datas array
        newArr[e] =!newArr[e];
        setSel_alimento(newArr);
    }
    //Recoge los estado seleccionados
    const handleEstado = (e) => {
        let newArr = [...sel_estado]; // copying the old datas array
        //Borra toda la matriz. Descomentar para que solo se pueda realizar una selección
        //newArr=newArr.map((i) =>{return false});
        newArr[e] =!newArr[e];
        setSel_estado(newArr);
    }
    //Realiza la búsqueda al apretarse el botón de búsqueda
    const handleSubmit =(e) =>{
        let new_alimento=sel_alimento.map(function(alimento,i){
            return alimento?n_alimentos[i]:'';
        });
        let new_estado=sel_estado.map(function(estado,i){
            return estado?'estas '+n_estados[i]:'';
        });
        const filtro=new_alimento+','+new_estado;
        const busca=pal_busca.current;
        const busca_alimento='';
        const data = {
            busca, // objeto busca
            filtro, // objeto filtro
            busca_alimento,
        };
        //Muestra la página d elos listados
        navigate('/Listado', { state: { data } });
    }

    const lista_al=n_alimentos.map((al,i)=>{
        return(
            <List.Item key={'alim_'+i}>
            <List.Content>
                <div onClick={() => handleAlimento(i)}>
                <Image avatar src={f_alimentos[i]} ui={false} className='icons-principal-size'/>
                <List.Header as='a'  style={{backgroundColor:sel_alimento[i]?'cyan':'transparent'}}>{al}</List.Header>
                </div>
            </List.Content>          
            </List.Item>           
        );
    })
    const lista_es=n_estados.map((al,i)=>{
        return(
            <List.Item key={'sta_'+i}>
            <List.Content>
                <div onClick={() => handleEstado(i)}>
                <Image avatar src={f_estados[i]} ui={false} className='icons-principal-size'/>
                <List.Header as='a'  style={{backgroundColor:sel_estado[i]?'cyan':'transparent'}}>{al}</List.Header>
                </div>
            </List.Content>          
            </List.Item>           
        );
    })

    return(
    <div>
        <Header as='h3' style={{marginBottom:0, marginTop:'0.5em'}}>¿Que te apetece?</Header>  
        <List selection horizontal size={'medium'}>
            {lista_al}
        </List>
        <Header as='h3' style={{marginBottom:0, marginTop:'0.5em'}}>¿Como te encuentras?</Header>  
        <List selection horizontal size={'medium'}>
            {lista_es}
        </List>
        <Dropdown
            fluid
            icon='search' 
            placeholder='Que buscamos...'  
            options={options}
            search
            selection
        />
        <Grid columns={2}>
            <Grid.Column width={2} style={{paddingLeft: 0,paddingRight: 0}}>
                <Popup
                    trigger={ <Button fluid>
                                    <Icon name="user" />
                                </Button>}
                    on="click"
                    position="top right"
                    closeOnDocumentClick
                    closeOnEscape
                    flowing
                    hoverable
                    style={{paddingLeft:'2em'}}
                >
                    <Dropdown.Menu>
                        <Menu_user></Menu_user>
                    </Dropdown.Menu>
                </Popup>
            </Grid.Column>
            <Grid.Column width={14} style={{paddingLeft: 0,paddingRight: 0}}>
                <Button loading={cargando}  positive fluid onClick={() => handleSubmit()} >
                    <Icon name="search" />
                    ¿Intento ayudarte? Pulsa me
                </Button>
            </Grid.Column>
        </Grid>
    </div>
    );

}
