import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom'
import { useState } from 'react'
import AccountConfirm from './view/confirm'
import Login from './view/login'
import Logout from './view/logout'
import Profile from './view/profile'
import Register from './view/register'
import ResetPassword from './view/reset'

import Seleccion_iconos from './principal/seleccion_iconos.jsx';
import Receta from './principal/receta.jsx';
import TrabajarRecetas from './principal/TrabajarRecetas.jsx';
import TrabajarSeguimiento from './principal/TrabajarSeguimiento.jsx';
import Listado from './principal/Listado.jsx';
import TablaNutsAlimento from './principal/TablaNutsAlimento.jsx';
import TablaNutsReceta from './principal/TablaNutsReceta.jsx';
import ListaIngredientes from './principal/ListaIngredientes.jsx';
import Problemas from './principal/problemas.jsx';

import Banner_cookies from './banner_cookies';

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const handleAcceptCookies = () => {
    setCookiesAccepted(true);
  };

  return ( 
    <div className="App">
      <Banner_cookies cookiesAccepted={cookiesAccepted} ></Banner_cookies>
      <Routes>
        <Route path="/" element={<Seleccion_iconos />} />
        <Route path="/home" element={<Seleccion_iconos />} />
        <Route path="/login" element={<Login onAcceptCookies={handleAcceptCookies} />} />
        <Route path="/register" element={<Register onAcceptCookies={handleAcceptCookies} />} />
        <Route path="/confirm" element={<AccountConfirm />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/receta" element={<Receta />} />
        <Route path="/TrabajarRecetas/*" element={<TrabajarRecetas />} />
        <Route path="/TrabajarSeguimiento" element={<TrabajarSeguimiento />} />
        <Route path="/Listado" element={<Listado />} />
        <Route path="/info_alimento" element={<TablaNutsAlimento />} />
        <Route path="/TablaNutsReceta" element={<TablaNutsReceta />} />
        <Route path="/ListaIngredientes" element={<ListaIngredientes />} />
        <Route path="/problemas" element={<Problemas />} />

      </Routes>
    </div>
  );
}

export default App;