import React, { useEffect, useState } from 'react';
import { Button, Table, Icon,Message,Modal } from 'semantic-ui-react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {url_base} from "./funciones.js"

import Alimentos_nutriente from './alimentos_nutiente.jsx';

export default function Tabla_nutrientes_alimento(props) {
    const cookies=localStorage.getItem('cookies')==='1'?true:false;
    const location=useLocation();
    const busca_al=location.state?location.state.busca_al:props.busca_al;
    const peso=props.peso?props.peso:null;
    const raciones=props.reciones?props.raciones:1;
    const navigate = useNavigate()
    const [lst_nuts,set_lst_nuts]=useState([]);
    const en_trabajo=props.datos?true:false;
    const [completa,set_completa]=useState(en_trabajo);
    const [modalAbierto, set_modalAbierto] = useState(false);
    const [nut_consulta,set_nut_consulta]=useState('');

    useEffect(() => {
        // eslint-disable-next-line
        do_search();
    }, [completa,busca_al]) //Estados que al modificar ejecutarán el código en esta función
  
    //Realiza la consulta para obtener la información nutriciona
    async function do_search(){
        let url = url_base();
        await fetch(url+`API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
                nutriente_alimento: busca_al,
                completa: completa,
            }),
            headers: {
              Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
          })   
        .then(response => response.json())
        .then(res => {
            //Publica el listado de recetas
            set_lst_nuts(res);
            if(props.nutrientes){
                props.nutrientes(res);
            }
        });
    }
    const handleClose = () => {
        if (!props.esModal){
            navigate(-1);
        }        
    };

    const handleMas = () => {
        set_completa(!completa);
    };

    const handleNutriente = (nut) => {
        set_nut_consulta(nut)
        set_modalAbierto(true);
    };

    function convertirUnidades(numeroTexto,i) {
        let numero = parseFloat(numeroTexto);
        //Adapta la cantidad al peso del alimento
        if (peso){
            numero=numero*(peso/100)/raciones;
        }
        if (i===0){
            return numero.toFixed(2)+' kCal';
        }
        if (parseInt(numero.toFixed(3).split('.')[0]) > 0) {
            return numero.toFixed(2)+' g';
        }else if (parseInt(numero.toFixed(3).split('.')[1]) > 0) {
            return (numero * 1000).toFixed(2) + ' mg';
        } else if (parseInt(numero.toFixed(6).split('.')[1]) > 0) {
            return (numero * 1000000).toFixed(2) + ' ug';
        } else {
            return numero.toFixed(2)+' g';
        }
    }      

    let filas=<Table.Row></Table.Row>
    if(lst_nuts.hasOwnProperty('nuts')){
        if (Object.keys(lst_nuts.nuts).length>0){
            filas= Object.keys(lst_nuts.nuts).map((key,i) => (
                <Table.Row key={key}>
                    <Table.Cell
                        onClick={() => handleNutriente(key)}
                    >{key}</Table.Cell>
                    <Table.Cell textAlign='right'>{convertirUnidades(lst_nuts.nuts[key],i)}</Table.Cell>
                </Table.Row>
            ));
        }
    }

    let tabla=(
        <Table celled unstackable={true} size={'small'}>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell textAlign='center'>
                        <Button 
                            size='large'
                            icon 
                            labelPosition='left'
                            onClick={handleClose}>
                            <Icon name='log out' />
                            Nutrientes que contiene {peso?(peso/raciones).toFixed(0):100}g de <b>{lst_nuts.alimento}</b>
                        </Button>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                    <Button
                        icon={completa?'minus':'plus'}
                        color='green'
                        position='left'
                        onClick={handleMas}
                    ></Button>
                </Table.HeaderCell>
            </Table.Row>               
            <Table.Row key='head'>
                <Table.HeaderCell>Nutriente</Table.HeaderCell>
                <Table.HeaderCell>peso</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
                {filas}
            </Table.Body>
            <Table.Footer>
            {!cookies && 
                <Table.Row>
                    <Table.HeaderCell colSpan="5">
                        <Message info>
                            <Message.Header>Cookies</Message.Header>
                            <p>Al no tener las cookies activadas, al volver atrás vuelve al inicio de la consulta</p>
                        </Message>    
                    </Table.HeaderCell>
                </Table.Row>
            }
            </Table.Footer>
            {/* Modal */}
            <Modal open={modalAbierto} onClose={() => set_modalAbierto(false)}>
                <Modal.Header>Alimentos con {nut_consulta} por 100 gr.</Modal.Header>
                <Modal.Content>
                    <Alimentos_nutriente en_trabajo={en_trabajo} nutriente= {nut_consulta} > </Alimentos_nutriente>
                </Modal.Content>
            </Modal>
        </Table>
        );

    return tabla;
}

