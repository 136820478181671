import React, { useState, useEffect } from "react";
import { Message,Grid } from "semantic-ui-react";

import Calendario from './calendario.jsx';

export default function TrabajarSeguimiento(){
    //const [selected_modal_Alimento, set_selected_modal_Alimento] = useState([]);
    const [show_mensaje, set_show_mensaje] = useState(false);
    const [mensaje, set_mensaje] = useState(null);

    useEffect(() => {
    }, []); //Variable que con el cambio de esta, quiere que lance evento
    
    const handleCloseMessage = () => {
        set_show_mensaje(false);
    };

    return(        
        <div>
            {show_mensaje && (
                <Message
                    onDismiss={handleCloseMessage}
                    header="Atención"
                    content={mensaje}
                />
            )}
            <Grid columns={2} divided>
                <Grid.Row style={{paddingLeft:10}}>
                    <Grid.Column style={{padding:0, width: '75%'}}>
                        {/* Tabla 2 */}
                        <Calendario />
                    </Grid.Column>
                    <Grid.Column style={{padding:0, width: '25%'}}>
                        {/* Tabla 3 */}
                    </Grid.Column>
                </Grid.Row>

                {/* Modal 
                <Modal open={modal_Abierto} onClose={() => set_modal_Abierto(false)}>
                    <Modal.Header>Información de {datos_modal_Alimento} por {(datos_modal_Peso*100).toFixed(0)} gr. Son {contenido.nutrientes?(datos_modal_Peso*100/(contenido.nutrientes.peso/300)).toFixed(0):null} gr. por ración </Modal.Header>
                    <Modal.Content>
                        <TablaNutsAlimento esModal busca_al={datos_modal_Alimento} peso={datos_modal_Peso} raciones={contenido.nutrientes?(contenido.nutrientes.peso/300):null} />
                    </Modal.Content>
                </Modal>
                */}
            </Grid>
        </div>
    );
}
