import React from 'react';
import { Menu, Dropdown } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom'

import './custom.css';

function Menu_user() {
    const navigate = useNavigate()

    const handleLogin = (e) =>{
        navigate('/login');
    }
    const handleProfile = (e) =>{
        navigate('/profile');
    }  
    const handleTrabajo_receta = (e) =>{
        navigate('/TrabajarRecetas');
    }    
    const handleSeguimiento_receta = (e) =>{
        navigate('/TrabajarSeguimiento');
    }   
    const handleLogout = (e) =>{
        navigate('/logout');
    }

    return (
        <>
            {localStorage.getItem('token') ? (
            <>
                <Dropdown.Item
                    onClick={handleProfile}
                    className="highlight-menu-item"
                >Perfíl</Dropdown.Item>
                <Dropdown.Item
                    onClick={handleTrabajo_receta}
                    className="highlight-menu-item"
                >Trabajo receta</Dropdown.Item>
                <Dropdown.Item
                    onClick={handleSeguimiento_receta}
                    className="highlight-menu-item"
                >Seguimiento receta</Dropdown.Item>
                <Dropdown.Item
                    onClick={handleLogout}
                    className="highlight-menu-item"
                >Salir</Dropdown.Item>
            </>
            ) : (
                <Dropdown.Item
                    onClick={handleLogin}
                    className="highlight-menu-item"
                >Login</Dropdown.Item>
            )}
        </>
    );
}

export default Menu_user;