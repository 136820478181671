export function url_base(){
    let url = window.location.href;
    const ultimoSlashIndex = url.lastIndexOf('/');
    if (ultimoSlashIndex>6) url = url.substring(0, ultimoSlashIndex);
    if(url.includes(':3000')){
        url = url.replace(/:\d+/, '');
        url+='/react/cana/';
    }else{
      url+='/';
    }

    return url;
}

//Realiza la consulta para guarda la información nutriciona
export async function explora_ficheros(ruta,setFiles,setCurrentPath){
    //Compone la cadena de búsqueda
    let url = url_base();
    await fetch(url + `API/database.php`, { 
        method: 'POST',
        body: JSON.stringify({
            explora: ruta,
        }),
        headers: {
            Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
        },
        })           
    .then(response => response.json())
    .then(res => {
        if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
            //Publica el listado de recetas
            if ('status' in res){
                if(res.status==1){
                    setCurrentPath(res.ruta);
                    setFiles(res.archivos);
                }
            }else{
                //ERROR, no lo ha podido procesar
            }            
        }else{
            //ERROR, no lo ha podido procesar
        }  
    });
}
