import React, { useState, useEffect } from "react";
import { Button, Table,Icon } from "semantic-ui-react";
import {url_base,explora_ficheros} from "./funciones.js"

import FileExplorerCell from "./FileExplorerCell.jsx";

export default function FileExplorer(props){
    const [currentPath, setCurrentPath] = useState("/");
    const [files, setFiles] = useState([]);

    useEffect(() => {
        explora_ficheros(props.ruta?props.ruta:'',setFiles,setCurrentPath);
    }, []); //Variable que con el cambio de esta, quiere que lance evento

    //Realiza la consulta para guarda la información nutriciona
    async function read_file(file){
        //Compone la cadena de búsqueda
        let url = url_base();
        await fetch(url + `API/database.php`, { 
            method: 'POST',
            body: JSON.stringify({
                recetas_trabajo_lf : file,
            }),
            headers: {
                Autorizacion_cana: 'Bearer ' + localStorage.getItem('token'),
            },
            })           
        .then(response => response.json())
        .then(res => {
            if (typeof res === 'object' && res !== null && Object.keys(res).length > 0){
                //Publica el listado de recetas
                if ('status' in res){
                    if(res.status==1){
                        //Envia el contenido del fichero seleccionado
                        if('contenido' in props){
                            res.contenido['carpeta']=currentPath.length?currentPath.split("/")[1]:'';
                            props.contenido(res.contenido);
                        }
                        //Indica que ha leido el fichero
                        if('ventana' in props){
                            props.ventana(false);
                        }
                    }
                }else{
                    //ERROR, no lo ha podido procesar
                }            
            }else{
                //ERROR, no lo ha podido procesar
            }  
        });
    }  
  
    const handleBack = () => {
      // Navegar hacia atrás (al directorio padre)
      const partesRuta = currentPath.split("/");
      partesRuta.pop();
      const rutaPadre = partesRuta.join("/");
      explora_ficheros(rutaPadre,setFiles,setCurrentPath);
    };
  
    const handleFileClick = (fileName, tipo) => {
        if (tipo === "carpeta") {
            // Navegar hacia la carpeta seleccionada
            explora_ficheros(`${currentPath}/${fileName}`,setFiles,setCurrentPath);
        } else if (tipo === "archivo") {
            // Realizar acciones cuando se hace clic en un archivo (por ejemplo, descargarlo)
            read_file(currentPath.length?currentPath+'/'+fileName:fileName);
        }
    };
  
    return (
        <div style={{ overflowX: 'auto' }}>
            <h2>Explorador de archivos</h2>
            <p>Ruta actual: {currentPath}</p>
            <Button onClick={handleBack} disabled={currentPath === "/"}>Atrás</Button>
            <Table celled >
                <Table.Header>
                    <Table.Row>
                    <Table.HeaderCell>Tipo</Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                    <Table.HeaderCell>Peso</Table.HeaderCell>
                    <Table.HeaderCell>Calorias</Table.HeaderCell>
                    <Table.HeaderCell>Ingredientes</Table.HeaderCell>
                    <Table.HeaderCell>Nutrientes</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {files.map((item, index) => (
                        <FileExplorerCell datos={item} handleFileClick={handleFileClick}/>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
}
